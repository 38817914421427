import React from 'react'
import projectUrl from '../../ProjectComponents/Url'

function IndexBenifit() {
    return (
        <>
            <section className="benefits mt-5 pt-5 pb-5">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-3 col-8 align-self-center">
                            <h1 className="">Our Benefits</h1>
                        </div>
                        <div className="col-lg-1 col-4 text-right">
                            <img src={projectUrl.hostUrl+"assets/img/icon/ds-3.svg"} width="80%" className="" alt="" />
                            <img src={projectUrl.hostUrl+"assets/img/icon/ds-1.svg"} className="tick_sign" alt="" />
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="row no-gutters">
                                <div className="col-12 col-md-6 pt-5 p-1">
                                    <div className="bx1">
                                        <div className="row">
                                            <div className="col-5">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/edu.svg"} alt="" />
                                            </div>
                                            <div className="col-7">

                                            </div>
                                            <div className="col-12 pt-4 pb-4">
                                                <h3>Student-centered</h3>
                                            </div>
                                            <div className="col-12">
                                                <p>Student-centered tools to cut through the noise and help you quickly focus on a short list of
                                                    colleges that match your interests.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 pt-5 p-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="bx2">
                                                <div className="row no-gutters">
                                                    <div className="col-3 align-self-center">
                                                        <img src={projectUrl.hostUrl+"assets/img/icon/lk.svg"} width="80%" alt="" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h3>100% free</h3>
                                                        <p>Tap into professional expertise and insights from current college students - absolutely free!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-1">
                                            <div className="bx3">
                                                <div className="row no-gutters">
                                                    <div className="col-3 align-self-center">
                                                        <img src={projectUrl.hostUrl+"assets/img/icon/pt.svg"} width="80%" alt="" />
                                                    </div>
                                                    <div className="col-9">
                                                        <h3>Easy to use</h3>
                                                        <p>Your easy-to-navigate guide for a smooth college selection and application process.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-12">
                            <img src={projectUrl.hostUrl+"assets/img/icon/ds-2.svg"} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexBenifit