import React, {useState, useEffect} from 'react'
import projectUrl from '../../ProjectComponents/Url'
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import { useNavigate } from 'react-router-dom';

function SearchCollegeBody() {
    const [findCollegesValue, setFindCollegesValue] = useState('');
    const [similarColleges, setSimilarColleges] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        // Reuse the fetchColleges logic for findCollegesValue
        const fetchSimilarColleges = async () => {
            if (findCollegesValue.length >= 3) {
                const data = { 'Name': findCollegesValue };

                try {
                    const response = await fetchData.post(projectUrl.getCollegeSuggestions, {}, data);
                    setSimilarColleges(response.data);
                } catch (error) {
                    console.error('Failed to fetch similar colleges:', error);
                }
            } else {
                setSimilarColleges([]);
            }
        };

        fetchSimilarColleges();
    }, [findCollegesValue]);

    const handlefindCollegeClick = (slug) => {
        navigate(`/college/${slug}`); // Navigate to the college page with the slug
    };

    const handleFindCollegesChange = (e) => {
        const value = e.target.value;
        setFindCollegesValue(value);
        // You can perform any additional actions you need here
    };
    return (
        <>
            <section className="collegeSearch pt-5 pb-5">
                <div className="container ">
                    <div className="row">
                        <div className="col-12 col-md-6 align-self-center pb-4 search_cl_div">
                            <h2>Welcome to the CollegesLike Search Page</h2>
                            <p className="pt-3">Enter one college that interests you. We'll find others that are culturally and demographically similar.</p>

                            <div className="input_field  ">
                                <div className="row">
                                    <div className="col-1 col-md-2 col-lg-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/collegeIcon.svg"} alt="" />
                                    </div>
                                    <div className="col-11 col-md-10 col-lg-11">
                                        <input
                                                onChange={handleFindCollegesChange}
                                                type="search"
                                                className="pl-2 college_name"
                                                autoComplete="off"
                                                name="findColleges"
                                                id="clg_name"
                                                placeholder="Enter a college name here"
                                                value={findCollegesValue}
                                            />
                                        
                                    </div>
                                </div>
                            </div>
                            {similarColleges.length > 0 && (
                                <div className="row no-gutters justify-content-center">
                                    <div className="col-11">
                                        <div className="listItemss pl-3 pr-3">
                                            <ul className="dropdown_menu--animated dropdown_menu-7 bg-dark suggestion_box">
                                                {similarColleges.map((college) => (
                                                    <li className='p-3 border' key={college._id} onClick={() => handlefindCollegeClick(college.Slug)}>
                                                        {college.Name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="HelpToFind p-4">
                                <h4 className="pb-3">If you’re having trouble getting started, try one of these to find your first college:</h4>
                                <div className="row no-gutters">
                                    <div className="col-1 col-md-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt="" />
                                    </div>
                                    <div className="col-11 col-md-11 pl-2">
                                        <p>Ask a school college counselor to recommend one college where you can begin your search.</p>
                                    </div>

                                    <div className="col-1 col-md-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt="" />
                                    </div>
                                    <div className="col-11 col-md-11 pl-2">
                                        <p>Think of someone you know who has already gone to college who was academically and socially like you. Where did they go to college?</p>
                                    </div>

                                    <div className="col-1 col-md-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt="" />
                                    </div>
                                    <div className="col-11 col-md-11 pl-2">
                                        <p>Ask a family member if they have any college recommendations.</p>
                                    </div>

                                    <div className="col-1 col-md-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt="" />
                                    </div>
                                    <div className="col-11 col-md-11 pl-2">
                                        <p>Enter the name of the closest college to your home.</p>
                                    </div>

                                    <div className="col-1 col-md-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt="" />
                                    </div>
                                    <div className="col-11 col-md-11 pl-2">
                                        <p>Enter the name of your home state’s biggest university and refine your search from there</p>
                                    </div>

                                    <div className="col-1 col-md-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt="" />
                                    </div>
                                    <div className="col-11 col-md-11 pl-2">
                                        <p>Sign up for a free campus tour at the college that is closest to your home. This will give you a sense of what to look for in the future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SearchCollegeBody