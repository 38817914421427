import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import projectUrl from '../../ProjectComponents/Url';
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import { useNavigate } from 'react-router-dom';
function DeleteBody() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleDelete =() =>{
    $.confirm({
      title: 'Warning!',
      content: 'Do you want to delete account?',
      
      buttons: {
          Delete :{
            btnClass: 'btn-red',
            action :async function () {
              // console.log('delete account'); // Adjust if needed, to redirect user to the homepage or login page
              const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };
              try {
                const response = await fetchData.delete(projectUrl.deleteAccount, config);
                localStorage.clear();
                navigate('/')
                console.log(response); // Handle success response
                // Reset form fields or show a success message
                $.alert({
                    title:'Success',
                    content: response.message,
                    type:'gree',
                    buttons: {
                        
                        ok: function() {
                          
                        },
                        
                    }
                })
            } catch (error) {
                console.error('Error submitting form:', error);
                // Handle error response
            }
            },
          },
          cancel: function () {
             
          },
          
      }
    });
  }
  return (
    <>
            <section className="backpack_section pt-5 pb-5">
            <div className="container">
                <h1 className="text-center">Delete Your Account</h1>

                <div className="delete-account-container">
                    <div className="delete-account-note">
                        <p>Dear User,</p>
                        <p>We understand that you are considering the decision to delete your account. We want to ensure you are fully informed about what this action means and its consequences. Please read the following information carefully before proceeding.</p>
                        <p><strong>1. Irreversible Action:</strong> Account deletion is permanent and irreversible. Once you delete your account, it cannot be recovered or restored.</p>
                        <p><strong>2. Loss of Data:</strong> All your data, including your personal information, preferences, and history, will be permanently deleted. This action is non-recoverable.</p>
                        <p><strong>3. Content Deletion:</strong> Any content or contributions you have made will also be permanently removed. This includes posts, comments, images, and any other data associated with your account.</p>
                        <p><strong>4. Subscription and Services:</strong> If you have any active subscriptions or ongoing services linked to your account, they will be immediately terminated without a refund.</p>
                        <p><strong>5. Financial Transactions:</strong> Ensure that all financial transactions, if any, are completed or canceled before proceeding. We are not responsible for any loss or inconvenience caused by unresolved transactions.</p>
                        <p><strong>6. Third-Party Associations:</strong> If your account is linked with any third-party services, you may lose access to those services. Please disassociate your account from third-party services before deletion.</p>
                        <p><strong>7. Re-Registration:</strong> After account deletion, you may lose the right to your username, and re-registration with the same username may not be possible.</p>
                        <p><strong>8. Legal Compliance:</strong> Your account deletion will comply with our privacy policy and applicable data protection laws. If there are any legal requirements for us to retain certain data, those will be honored.</p>
                        <p><strong>9. Email Confirmation:</strong> After initiating the deletion process, you will receive an email to confirm the deletion. You must confirm via this email to complete the process.</p>
                        <p><strong>10. Time Frame:</strong> The deletion process may take a few days to complete. During this time, your account will be inaccessible.</p>
                        <p>We recommend that you download any data or information you wish to retain before proceeding with the deletion.</p>
                        <p>If you have any questions or need assistance, please contact our support team before making this decision.</p>
                        <p>If you are certain you wish to proceed, please click the button below. Remember, this action is irreversible, and you will lose all access to your account and its data permanently.</p>
                        <p>Best Regards,</p>
                        <p><b>CollegesLike</b></p>
                    </div>
                    <Link to={"#"} className="">
                    <button className="btnTyp2 p-3 pl-4 pr-4" onClick={handleDelete} id="">
                      Delete Account
                      <span className="first"></span>
                      <span className="second"></span>
                      <span className="third"></span>
                      <span className="fourth"></span>
                    </button>
                  </Link>
                </div>

            </div>

        </section>
    </>
  )
}

export default DeleteBody