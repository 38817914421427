import React, { useEffect, useState } from 'react'
import SearchCollgeBanner from './Componets/SearchCollgeBanner'
import SearchCollegeBody from './Componets/SearchCollegeBody'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';
// import TopBar from '../ProjectComponents/TopBar'

function SearchCollegePage() {
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('search-college');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);

  // const page = 'search-college'
  return (
    <>
        {/* topbar */}
        <div className='bg-main main'>
          {/* <TopBar/> */}
          <SearchCollgeBanner/>
        </div>
        <SearchCollegeBody/>
        <IndexFooter/>
    </>
  )
}

export default SearchCollegePage