import React from 'react'
import { Link } from 'react-router-dom'
import projectUrl from '../../ProjectComponents/Url'

function IndexFooter() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // for smooth scrolling
        });
      };
    return (
        <>
            <section className="Mfooter bg-dark pt-5 pb-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 order-2 order-md-1 col-lg-3  col-12 text-center text-md-left align-self-center">
                            <ul className="pt-5 pt-md-0 pb-4 pb-md-0">
                                <li><Link className="link-hover" to={projectUrl.hostUrl+"terms-condition"}> Terms of Use</Link></li>
                                <li><Link className="link-hover" to={projectUrl.hostUrl+"about"}> About</Link></li>
                                <li><Link className="link-hover" to={projectUrl.hostUrl+"school"}> Schools</Link></li>
                                <li><Link className="link-hover" to={projectUrl.hostUrl+"methodology"}> Methodology</Link></li>
                                <li><Link className="link-hover" to={projectUrl.hostUrl+"contact"}> Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-4 order-1 order-md-2 col-lg-6  col-12 text-center">
                            <Link to={'/'}>
                                <img src={`${projectUrl.hostUrl}assets/img/white_logo.svg`} width="90%" alt="" />
                            </Link>
                        </div>
                        <div className="col-md-4 order-3 col-lg-3 col-12 justify-content-center align-self-center center_box">

                            <div className="row no-gutters">
                                <div className="col-md-12 col-12">
                                    <button className="return_btn btn d-flex float-right" onClick={scrollToTop}>
                                        <img src={`${projectUrl.hostUrl}assets/img/icon/upArrow.svg`} className="float-right mt-2" alt="" /> <h6 className="float-right text-white pl-2 mt-2">RETURN TO TOP</h6>
                                    </button>
                                </div>
                                <div className="col-md-12 col-6">
                                    <Link to="https://apps.apple.com/in/app/collegeslike/id1661180881" target="_blank">
                                        <button className="float-right btn p-0 app_link bg-white">
                                            <img src={`${projectUrl.hostUrl}assets/img/apple.png`} width="100%" alt='' />
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-md-12 col-6 pl-0 pl-sm-2">
                                    <Link to="https://play.google.com/store/apps/details?id=com.app.collegeslike" target="_blank">
                                        <button className="float-right btn p-0 app_link bg-white">
                                            <img src={`${projectUrl.hostUrl}assets/img/android.png`} width="100%" alt=''/>
                                        </button>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center pt-1">

                            <p className="text-white">Copyright &copy; 2024 CollegesLike LLC.
                                All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexFooter