import React, { useState } from 'react';
import { GoogleMap, MarkerF, InfoWindowF, } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
const containerStyle = {
  width: '100%',
  height: '650px',
};

function MapWithMarker({ lat, lng, tooltip }) {
  const [activeMarker, setActiveMarker] = useState(null);
  
  // Define center outside of component function if it doesn't depend on props
  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCDc0Q7OAGczAeSVGEjKm1avct0QqZdU9s" // Replace with your actual API key
  });
  const handleOnMouseOver = () => {
    if(center === activeMarker){
        return null;
    }
    setActiveMarker(center); // Set marker to active when mouse is over
  };

  const handleOnMouseOut = () => {
    setActiveMarker(null); // Remove active marker when mouse is out
  };

  if (!isLoaded) {
    return <div>Loading...</div>; // Or any other loading state you want to show
  }
  return (
    
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
      >
        <MarkerF
          position={center}
          onMouseOver={handleOnMouseOver}
          onCloseClick={handleOnMouseOut}
        />

        {activeMarker && (
          <InfoWindowF
            position={activeMarker}
            onCloseClick={handleOnMouseOut}
          >
            <div>{tooltip}</div>
          </InfoWindowF>
        )}
      </GoogleMap>
   
  );
}

export default React.memo(MapWithMarker);
