import React from 'react'
import projectUrl from '../../ProjectComponents/Url'

function DeleteBanner() {
  return (
    <>
            <div className="hero-area pt-4 pt-sm-1 pt-lg-3">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-6 col-12 order-1 order-md-2">
                            <img src={projectUrl.hostUrl+"assets/img/content-img/backpack-bn.png"} className="large_view_img" width="100%" alt="school-girl" />
                            <img src={projectUrl.hostUrl+"assets/img/content-img/backpack-bn.png"} className="small_view_img" width="100%" alt="" />
                        </div>
                        <div className="col-md-6 col-12 order-2 order-md-1 align-self-md-center align-self-start">
                            <h3 className="pt-3 pt-sm-3 pt-md-0 pr-0 pr-sm-0 pr-md-5"><span className="text-primary">Free, fast,</span> and 
                                <span className="text-primary"> helpful</span> information for college searches and the application process.
                            </h3>
                            <p className="pt-2">Developed by college admission professionals.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default DeleteBanner