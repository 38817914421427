import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import projectUrl from '../../ProjectComponents/Url'
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import CustomModal from '../../Index/Components/VdPlayer';

function CollegeStBody() {
    const [colleges, setColleges] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState('');
    const fetchCollegeData = async () => {

        try {
            const response = await fetchData.get(projectUrl.fetchStudentVideos); // Replace with your actual API endpoint
            
            console.log('This is '+response);
            setColleges(response.data); // Assuming the API response format matches the given example
        } catch (error) {
            console.error('Error fetching college data:', error);
        } 
    };

    useEffect(() => {
        fetchCollegeData();
    }, []); 

    const handleCollegeClick = (videoId) => {
        setSelectedVideoId(videoId);
        setShowModal(true);
    };
    return (
        <>
            <section className="student_videos bg-main mt-5 pb-5">
                <div className="container pt-5">
                    <h2>Watch Student Videos from <br />These Colleges</h2>
                    <div className="row">
                        
                            {colleges.map((college) => (
                                <div key={college._id} className="col-12 col-lg-6 pt-3">
                                    <Link 
                                    to="#"
                                    className="play_js_video"
                                    onClick={() => handleCollegeClick(college.video)}
                                    > {/* Update `to` attribute as needed */}
                                        <div className="row no-gutters video_list">
                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl + "assets/img/icon/playyelowcolor.svg"} alt="" className="float-right" />
                                            </div>
                                            <div className="col-11 pt-1">
                                                <h5 className="pl-3">{college.college_name}</h5>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}

                    </div>
                </div>
            </section>

            <CustomModal
            show={showModal}
            onHide={() => setShowModal(false)}
            videoId={selectedVideoId}
        />
        </>
    )
}

export default CollegeStBody