import React, { useEffect, useState } from 'react';
import projectUrl from '../../ProjectComponents/Url'
import { useLocation, useNavigate } from 'react-router-dom';
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import $ from 'jquery';

function RegistrationBanner() {
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const location = useLocation();
    const navigate = useNavigate(); 
    const userType = location.state?.userType;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nickName, setNickName] = useState('');
    const [email, setEmail] = useState('');
    const [stateId, setStateId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handnickNameChange = (e) => setNickName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleStateChange = (e) => setStateId(e.target.value);
    const handleCountryChange = (e) => setCountryId(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
    const handleAgreeToTermsChange = (event) => setAgreeToTerms(event.target.checked);

    const registrationSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
      
        // Frontend validation
        if (password !== confirmPassword) {
          
          $.alert({
            title:'Warning !',
            content: "Passwords don't match!",
            type:'red',
            buttons :{
              Ok :{
                text:'OK',
                btnClass:'btn-red'
              }
            }
            
          });
          return;
        }
        if (!agreeToTerms) {
            
            $.alert({
                title:'Warning !',
                content: "You must agree to the terms of service.",
                type:'red',
                buttons :{
                  Ok :{
                    text:'OK',
                    btnClass:'btn-red'
                  }
                }
                
              });
            
            return;
        }
        // Construct the body of the request
        const requestBody = {
          User_Type: userType,
          First_Name: firstName,
          Nickname: nickName,
          Last_Name: lastName,
          Email: email,
          state_id: stateId,
          country_id: countryId,
          Password: password,
        };
      
        try {
          const response = await fetchData.post(projectUrl.userRegistration, {}, requestBody);
          if (response.loggedIn) {
            // Assuming the response structure matches your example
            localStorage.setItem('token', response.token);
            localStorage.setItem('first_name', response.first_name);
            localStorage.setItem('Email', response.data.Email);
            localStorage.setItem('loggedIn', response.loggedIn.toString());
            // Navigate to the dashboard or home page after successful login
            navigate('/'); // Adjust the route as necessary
          } else {
            $.alert({
                title: 'Warning!',
                content: 'Login failed: ' + response.message, // Assuming the message is a string
                type:'red',
                buttons: {
                    ok: {
                        text:'OK',
                        btnClass: 'btn-red'
                    }
                }
            });
             // Show error message from the response
          }
          // ... additional actions on success (e.g., redirect to another page)
        } catch (error) {
         
          $.alert({
            title: 'Warning!',
            content: `Registration failed: ${error.response.data.message}`, // Assuming the message is a string
            type:'red',
            buttons: {
                ok: {
                    text:'OK',
                    btnClass: 'btn-red'
                }
            }
        });
        }
      };
   

    
    useEffect(() => {
        if (!userType) {
            
            navigate('/login'); // Update '/your-redirect-route' to your actual route
        }
    }, [userType, navigate]);


    useEffect(() => {
        const fetchStates = async () => {
          
          try {
            const response = await fetchData.get(projectUrl.fetchState);
            setStates(response.data); // Assuming the states are in response.data.data
          } catch (error) {

          $.alert({
            title: 'Warning!',
            content: 'Error to fetch State please reload '+ error, // Assuming the message is a string
            type:'red',
            buttons: {
                ok: {
                    text:'OK',
                    btnClass: 'btn-red'
                }
            }
        });
          }
        };

        const fetchCountries = async () => {
            try {
              const response = await fetchData.get(projectUrl.fetchCountries);
              setCountries(response.data); // Assuming the countries are in response.data.data
            } catch (error) {
              console.error('Failed to fetch countries:', error);
              $.alert({
                title: 'Warning!',
                content: 'Error to fetch Countries please reload '+ error, // Assuming the message is a string
                type:'red',
                buttons: {
                    ok: {
                        text:'OK',
                        btnClass: 'btn-red'
                    }
                }
            });
            }
          };

        fetchCountries();
        fetchStates();
        
      }, []);
  return (
    <>
        <section className="registration">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 pt-5">
                        <img src={`${projectUrl.hostUrl}/assets/img/content-img/registration_bn.png`} className="large_view_img" width="100%" alt="school-girl" data-pagespeed-url-hash="1516700961" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"/>
                    </div>
                    <div className="col-12 col-lg-6 pt-5">
                        <h1>
                            New User Registration
                        </h1>
                        <p className='text-dark'>for {userType}</p>
                        <form onSubmit={registrationSubmit}>
                            <input type="hidden" name="User_Type" value={userType} />
                            <div className="row pt-4 pt-lg-4">
                                <div className="col-12 col-lg-6 pt-3">
                                    
                                    <div className="custom_field">
                                        <div className="row no-gutters field">
                                            <div className="col-1">
                                                <img src={`${projectUrl.hostUrl}/assets/img/icon/userIcon.svg`} className='pb-1' width={'17px'} alt=""  />
                                            </div>
                                            <div className="col-11">
                                                <input type="text" value={firstName} className='pl-1' placeholder="First/Given Name" name="First_Name" onChange={handleFirstNameChange} required/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 col-lg-6 pt-3">
                                    <div className="custom_field">
                                        <div className="row no-gutters field">
                                            <div className="col-1">
                                                <img src={`${projectUrl.hostUrl}/assets/img/icon/userIcon.svg`} className='pb-1' width={'17px'} alt="" />
                                            </div>
                                            <div className="col-11">
                                                <input type="text" value={nickName} className='pl-1' placeholder="Preferred/Nickname" name="Nickname" onChange={handnickNameChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 pt-3">
                                    <div className="custom_field">
                                        <div className="row no-gutters field">
                                            <div className="col-1">
                                                <img src={`${projectUrl.hostUrl}/assets/img/icon/userIcon.svg`} className='pb-1' width={'17px'} alt=""  />
                                            </div>
                                            <div className="col-11">
                                                <input type="text" value={lastName} className='pl-1' placeholder="Last/Family Name" name="Last_Name" required onChange={handleLastNameChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 pt-3">
                                    <div className="custom_field">
                                        <div className="row no-gutters field">
                                            <div className="col-1 ">
                                                <img src={`${projectUrl.hostUrl}/assets/img/icon/envelope.svg`} width={'19px'}  alt="input icon envelope"  />
                                            </div>
                                            <div className="col-11">
                                                <input type="email" value={email} placeholder="Email" id="User_Email"  className='pl-2'  name="Email" required onChange={handleEmailChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <small className="text-danger pl-2" style={{display:'none'}} id="Email_Error">Email Id Is Already Registered With Us</small>
                                </div>

                              

                                <div className="col-12 col-lg-6 pt-3">
                                    <div className="custom_field">
                                        <div className="row no-gutters p-2 pl-3">
                                            <div className="col-1 col-md-2">
                                                <img src={`${projectUrl.hostUrl}/assets/img/icon/city.svg`} width={'20px'} className='mt-1' alt="Select city icon" />
                                            </div>
                                            <div className="col-11 col-md-10">
                                                <select className="p-2 selectDropdown" name="country_id" value={countryId} onChange={handleCountryChange}>
                                                        <option disabled value=""> Select Country </option>
                                                        {countries.map((country) => (
                                                            <option key={country._id} value={country._id}>{country.country_name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 pt-3">
                                    <div className="custom_field">
                                        <div className="row no-gutters p-2 pl-3">
                                            <div className="col-1 col-md-2">
                                                <img src={`${projectUrl.hostUrl}/assets/img/icon/city.svg`} width={'20px'} className='mt-1'  alt="City icon Images" />
                                            </div>
                                            <div className="col-11 col-md-10">
                                            <select className="p-2 selectDropdown" name="state_id" id="" value={stateId} onChange={handleStateChange}>
                                                <option selected disabled> Select State </option>
                                                {states.map((state) => (
                                                    <option key={state._id} value={state._id}>{state.state_name === 'Not Applicable' ? state.state_name:state.state_name +','+ state.state_short_name}</option>
                                                ))}
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 pt-3">
                                    <div className="custom_field">
                                        <div className="row no-gutters field">
                                            <div className="col-1">
                                                <img src={`${projectUrl.hostUrl}/assets/img/icon/locker.svg`} width={'15px'} className='pb-1' alt=""/>
                                            </div>
                                            <div className="col-11">
                                                <input type="password" value={password} className='pl-1' placeholder="Enter Password" id="User_Password" name="" required onChange={handlePasswordChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 pt-3">
                                    <div className="custom_field">
                                        <div className="row no-gutters field">
                                            <div className="col-1 col-md-1">
                                                <img src={`${projectUrl.hostUrl}assets/img/icon/locker.svg`} width={'15px'} className='pb-1' alt="" />
                                            </div>
                                            <div className="col-11 col-md-11">
                                                <input autocomplete="off" value={confirmPassword} className='pl-1' type="password" placeholder="Enter Confirm Password" name="Password" required onChange={handleConfirmPasswordChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 pt-4">

                                    <div className="form-checks">
                                        <input className="form-check-input" type="checkbox" value={agreeToTerms} name="terms_of_use" id="agree" onChange={handleAgreeToTermsChange}/>
                                        <label className="form-check-label bold" for="agree">I agree to the terms of service of CollegesLike.com</label>
                                    </div>

                                    <div className="row justify-content-center pt-4 pb-4">
                                        <div className="col-lg-6 col-auto col-md-8">
                                            <button type='submit' className="btnTyp2 p-3 pl-4 pr-4" id="Registration_Form_Submit" >
                                                    SUBMIT REGISTRATION
                                                    <span className="first"></span>
                                                    <span className="second"></span>
                                                    <span className="third"></span>
                                                    <span className="fourth"></span>
                                                </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default RegistrationBanner