import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import projectUrl from "../../ProjectComponents/Url";
import fetchData from "../../ProjectComponents/GlobalFetchApi";
import $ from 'jquery';
function BackpackWithData() {
  const [backpackData, setBackpackData] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

    // State for form inputs
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchBackpackData = async () => {
       // Retrieve the token from localStorage
      if (!token) {
        // If no token is found, navigate to the login page
        navigate("/login");
        return;
      }
      // console.log(token);
      try {
        // Include the Authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetchData.get(projectUrl.backpack, config);
        if (response && response.data) {
          setBackpackData(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // If a 403 error is caught, clear localStorage and navigate to the login page
          localStorage.clear();
          $.alert({
            title:'Warning !',
            content:  error.response.message,
            type:'red',
            buttons :{
              Ok :{
                text:'OK',
                btnClass:'btn-red'
              }
            }
            
          });
          navigate("/login");
        } else {
          console.error("Failed to fetch backpack data:", error);
          // Handle other errors as needed
        }
      }
    };

    fetchBackpackData();
    
  }, [navigate, token]); // Include navigate in the dependency array

  const handleInterestChange = (itemIndex, interestKey) => (event) => {
    
    

    const newValue = event.target.checked;
  
    // Create a new array with updated interests for the specific item
    const newData = backpackData.map((item, index) => {
      console.log(itemIndex);
      if (index === itemIndex) {
        return {
          ...item,
          interest: {
            ...item.interest,
            [interestKey]: newValue,
          },
        };
      }
      return item;
    });
    console.log(newData);
  
    setBackpackData(newData);
  
    // Now prepare and send your API request with the updated interests
    const requestBody = {
      college_id: backpackData[itemIndex].collage_id, // Ensure this is the correct property name
      interest: newData[itemIndex].interest,
    };
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    // Call the API
    fetchData
      .post(projectUrl.updateInterest, config, requestBody)
      .then((response) => {
        console.log('Interest updated successfully', response);
      })
      .catch((error) => {
        console.error('Failed to update interest', error);
      });
  };


  const handleAdChanceChange = (itemIndex, tierKey) => (event) => {
    
    

    const newValue = event.target.checked;
  
    // Create a new array with updated interests for the specific item
    const newData = backpackData.map((item, index) => {
      console.log(itemIndex);
      if (index === itemIndex) {
        return {
          ...item,
          tier: {
            ...item.tier,
            [tierKey]: newValue,
          },
        };
      }
      return item;
    });
    console.log(newData);
  
    setBackpackData(newData);
  
    // Now prepare and send your API request with the updated interests
    const requestBody = {
      college_id: backpackData[itemIndex].collage_id, // Ensure this is the correct property name
      tier: newData[itemIndex].tier,
    };
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    // Call the API
    fetchData
      .post(projectUrl.updateTier, config, requestBody)
      .then((response) => {
        console.log('Interest updated successfully', response);
      })
      .catch((error) => {
        console.error('Failed to update interest', error);
      });
  };
  
  let notesTimeoutId = null;

  const handleNotesChange = (itemIndex) => (event) => {
    const newNotes = event.target.value;
    if (notesTimeoutId !== null) {
      clearTimeout(notesTimeoutId);
    }
    notesTimeoutId = setTimeout(() => {
    // Update the specific item's notes in your backpackData state
    const newData = backpackData.map((item, index) => {
      if (index === itemIndex) {
        return {
          ...item,
          notes: newNotes,
        };
      }
      return item;
    });
  
    setBackpackData(newData);
  
    // Prepare the API request body
    const requestBody = {
      college_id: backpackData[itemIndex].collage_id, // Ensure this is the correct property name
      notes: newNotes,
    };
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    // Call the API
    fetchData
      .post(projectUrl.updateNotes, config, requestBody) // Assuming you have a specific endpoint for notes
      .then((response) => {
        console.log('Notes updated successfully', response);
      })
      .catch((error) => {
        console.error('Failed to update notes', error);
      });
    }, 2000);
  };
  

  const deleteBackpack = (itemId) => {
    // Confirm with the user before deletion
    $.confirm({
      title: 'Warning!',
      content: 'Do you want to remove college from backpack?',
      
      buttons: {
          Delete :{
            btnClass: 'btn-red',
            action : function () {
              const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };


              const requestBody = {
                college_id: itemId, // Adjust the property name as per your API's expectation
                
              };
              

              fetchData.delete(projectUrl.deleteBackPack, config, requestBody)
                .then(() => {
                  // console.log('Item deleted successfully');
                  // Remove the item from backpackData by filtering it out
                  const updatedData = backpackData.filter(item => item.collage_id !== itemId);
                  setBackpackData(updatedData);
                })
                .catch(error => {
                  console.error('Failed to delete backpack item', error);
                });


            },
          },

          cancel: function () {
             
          },
          
      }
    });
  
  };
  


    // Prepare the request body with the itemId
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);
    const handleMessageChange = (e) => setMessage(e.target.value);

    const handleEmailSubmit = async (e) => {
      e.preventDefault(); // Prevent form from causing a page reload
  
      const requestBody = {
        name: name,
        email: email,
        message: message,
      };
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      try {
        const response = await fetchData.post(projectUrl.sendBackpackEmail, config, requestBody);
        
        $.alert({
          title:'Success !',
          content: response.message,
          type:'red',
          typeAnimated: true,
          buttons: {
              Ok: {
                  text: 'Ok',
                  btnClass: 'btn-red',
                  action: function(){
                    setEmail('');
                    setName('');
                    setMessage('');
                  }
              },
            }

        });
        // Handle successful email sending (e.g., show a confirmation message)
      } catch (error) {
        console.error('Failed to send backpack email', error);
        // Handle errors (e.g., show an error message)
      }
    };

  const hasData = backpackData && backpackData.length > 0;
  return (
    <>
      <section className="backpack_section pt-5 pb-5">
        <div className="container">
          <h1>Welcome to Backpack</h1>
          <p className="pt-3 pb-3">
            Store information about colleges you like and share it with others.
          </p>
          <div className="row justify-content-center">
            <div className="col-12 col-md-5"></div>
          </div>

          {/*collegeslike backpack*/}
          {hasData ? (
            backpackData.map((item,bacPackIndex) => (
              <div key={bacPackIndex} className="row no-gutters mt-3">
                <div className="col-12 col-lg-6  ">
                  <div className="content">
                    <div className="row no-gutters backPackHeading d-flex">
                      {/*collegeslike backpack heading start*/}

                      <div className="col-6 d-flex border-right-white">
                        <div className="heading_college p-2">
                          <p className="text-white text-center backpack_heading_p">
                            COLLEGES
                          </p>
                        </div>
                      </div>
                      <div className="col-6 d-flex border-right-white">
                        <div className="heading_notes p-2">
                          <p className="text-white text-center backpack_heading_p">
                            NOTES
                          </p>
                        </div>
                      </div>

                      {/*collegeslike backpack heading end*/}
                    </div>
                    <div className="row no-gutters justify-content-center collegesDetailsRow">
                      <div className="col-6  border-full-wh">
                        <div className="collegeDetails pl-3 pt-4 pb-4">
                          <Link to={`/colleges/${item.college.Slug}`}>
                            <h5 className="text-dark font-weight-bold">
                              {item.college.Name}
                            </h5>
                          </Link>
                          <p className="float-left">{item.college.State}</p>
                          <Link onClick={()=>{deleteBackpack(item.collage_id)}}
                            className="float-right pr-3"
                            // href="javascript:void(0)" onclick="return DeleteBackpack('398');"
                          >
                            <div className="d-flex delete_backpack">
                              <img
                                src={
                                  projectUrl.hostUrl +
                                  "assets/img/icon/deleteIcon.svg"
                                }
                                alt=""
                              />
                              <h6 className="pt-2 text-dark mb-2">Remove</h6>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div className="col-6  border-full-wh d-flex">
                        <div className="college_note">
                          <textarea
                            defaultValue={item.notes}
                            name="note"
                            className="notes"
                            id="notes"
                            cols="30"
                            rows="4"
                            onChange={handleNotesChange(bacPackIndex)}
                            placeholder="Write Some notes here"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6  ">
                  <div className="content">
                    <div className="row no-gutters backPackHeading d-flex">
                      <div className="col-6 d-flex border-right-white">
                        <div className="heading_interest p-2">
                          <p className="text-white text-center backpack_heading_p">
                            MY INTEREST
                          </p>
                        </div>
                      </div>
                      <div className="col-6 d-flex border-right-white">
                        <div className="heading_chance p-2">
                          <p className="text-white text-center backpack_heading_p">
                            MY ADMISSION CHANCE
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row no-gutters justify-content-center collegesDetailsRow">
                      <div className="col-6  border-full-wh ">
                        <div className="interest pt-3 ">
                          {Object.entries(item.interest).map(([key, value], index) => (
                            <div key={index} className="form-check pt-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={value}
                                onChange={handleInterestChange( bacPackIndex,key)}
                                id={`${item._id}-${key}`}
                              />

                              <label
                                className="form-check-label1"
                                htmlFor={`${item._id}-${key}`}
                               
                              >
                                {key}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-6  border-full-wh">
                        <div className="chanceRate pt-3 pb-2">
                          {Object.entries(item.tier).map(([key, value],index) => (
                            <div key={index} className="form-check pt-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={value}
                                onChange={handleAdChanceChange( bacPackIndex,key)}
                                id={`${item._id}-tier-${key}`}
                              />
                              <label
                                className="form-check-label1"
                                htmlFor={`${item._id}-tier-${key}`}
                              >
                                {key}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              className="alert text-center text-danger alert-danger p-4"
              role="alert"
            >
              <div className="row">
                <div className="col-12 col-md-8">
                  <h4 className="text-left">
                    Sorry! No college Found, click here to search a new college{" "}
                  </h4>
                </div>
                <div className="col-12 col-md-4">
                  <Link to={"/"} className="float-right">
                    <button className="btnTyp2 p-3 pl-4 pr-4" id="">
                      Search College
                      <span className="first"></span>
                      <span className="second"></span>
                      <span className="third"></span>
                      <span className="fourth"></span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {/*collegeslike backpack*/}
          {hasData?
          <div className="row justify-content-center">
            <div className="col-12">
              {/* <!-- </div> --> */}
              <h1 className="font-weight-bold pt-5 h3">
                Email The Backpack to Someone{" "}
              </h1>
              <div className="r_regis2">
                <form className="mt-5" onSubmit={handleEmailSubmit}>
                  
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-4 col-form-label font-weight-bold"
                    >
                      Recipient’s Email Address :
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="email"
                        name="Email"
                        className="form-control input_box"
                        id="inputEmail3"
                        required
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="col-sm-4 col-form-label font-weight-bold"
                    >
                      Recipient’s Name:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        required
                        className="form-control input_box"
                        name="Name"
                        value={name}
                        placeholder="Name"
                        onChange={handleNameChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="col-sm-4 font-weight-bold"
                    >
                      Message to Recipient:
                    </label>
                    <div className="col-sm-8 text-lg-right text-sm-right text-md-right text-center">
                      <textarea
                        required
                        name="Message"
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Message"
                        value={message}
                        onChange={handleMessageChange}
                      ></textarea>
                      {/* <!-- <button type="submit" className="btn btn-color secondary_btn waves-effect waves-light m-0 mt-5"
                                style="width: 170px;">Submit</button> --> */}

                      <button
                        className="btnTyp2 p-3 pl-4 pr-4 mt-5"
                        type="submit"
                        id=""
                      >
                        SEND BACKPACK
                        <span className="first"></span>
                        <span className="second"></span>
                        <span className="third"></span>
                        <span className="fourth"></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>:<div></div>}
        </div>
      </section>
    </>
  );
}

export default BackpackWithData;
