import React, { useEffect, useState } from 'react'
import ContactBody from './Components/ContactBody'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';
// import TopBar from '../ProjectComponents/TopBar'

function ContactPage() {
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('contact');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);
  // const page = 'contact'
  return (
    <>
      
        <div className='bg-findColleges contact main'>
          {/* <TopBar/> */}
          <ContactBody/>
        </div>

        {/* footer */}
        <IndexFooter/>
    </>
  )
}

export default ContactPage