import React from 'react'
import { Link } from 'react-router-dom'
import projectUrl from '../../ProjectComponents/Url'

function IndexSign() {
    return (
        <>
            <section className="user_Auth">
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-6 text-right">
                            <Link to={projectUrl.hostUrl+"login"}>
                                <button className="btn signUP">
                                    SIGN UP
                                </button>
                            </Link>
                        </div>
                        <div className="col-6 text-left">
                            <Link to={projectUrl.hostUrl+"login"}><button className="btn signUP">LOG IN</button></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexSign