import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import projectUrl from '../../ProjectComponents/Url'
import { useParams, useNavigate } from 'react-router-dom';
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import $ from 'jquery';

function CollegesLikeThisBody() {
    const { slug } = useParams(); 
    const [apiData, setApiData] = useState({data: [], aidata: []});
    const navigate = useNavigate();
    

    useEffect(() => {

        const fetchCollegeData= async () => {
            // console.log(slug);
            const requestBody = {
                college : slug
            }

            try {
                const response = await fetchData.post(projectUrl.fetchCollegesLike,{},requestBody);
                // console.log(response);
                setApiData(response);
                // console.log(collegeDetails);
            } catch (error) {
                console.error("Failed to fetch college details:", error);
            }
        };

        fetchCollegeData();

    },[slug])

    const handelAddBackpack= (collegeId)=>{
        const token = localStorage.getItem("token"); // Retrieve the token from localStorage
        $.confirm({
            title: 'Backpack add',
            content: 'Do you want to add this college in your list?',
            
            buttons: {
                Add :{
                  btnClass: 'btn-red',
                  action :async function () {
                    const requestBody = {
                        college_id : collegeId
                    }
                    const config = {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      };
                    try {
                        
                        const response = await fetchData.post(projectUrl.addtoBackPack, config,requestBody);
                        $.alert({
                            title:'Success !',
                            content:`${response.message}`,
                            button:{
                                ok:{
                                    btnClass: 'btn-green'
                                }
                            }
                        });
                        
                    } catch (error) {
                        if(error.response && (error.response.status === 401) ){
                            $.alert({
                              title: 'Login Please!',
                              content: error.response.data.message, // Assuming the message is a string
                              btnClass: 'btn-green',
                              buttons: {
                                  ok: function() {
                                    localStorage.clear();
                                    navigate('/login');
                                    console.log('hello');
                                  }
                              }
                          });
                        }
                    }
                  },
                },
                cancel: function () {
                   
                },
                
            }
          });
        
    }
  return (
    <>
        <section className="searchResult">

                <div className="container">
                    <div className="title_college pt-3 pb-3">
                        <h2>CollegesLike </h2>
                        <h2>{apiData.name} Include</h2>
                    </div>
                </div>

        </section>

        {/* ---------------body--------------- */}
        {!apiData.hasAiRecomends?
        <section className="similarCollege">
        <div className="container">
            <img src={projectUrl.hostUrl+"assets/img/icon/starIcon.svg"} width="100%" className="mystart" alt="" />
            <img src={projectUrl.hostUrl+"assets/img/icon/linetera.svg"} width="100%" className="myline" alt="" />
            <div className="row pt-2 pb-5">
                <div className="col-12  pt-3 pb-3">
                    {(apiData.data).map((collegesLike, index)=>(
                        <div key={collegesLike._id} className={`collegesCard ${index % 2 === 0 ? 'bg-white' : 'bg-secondColor'} p-4 mt-4`}>

                        <div className="row no-gutters">
                            <div className="col-12 col-lg-4 ">
                                <div className="d-flex">
                                    {/* <!-- <img src="/assets/img/content-img/collge_Icon.svg" width="20%" alt=""> --> */}
                                    <h5 className="pt-lg-3 pt-5 pl-2 pr-2">{collegesLike.Name} ({collegesLike.State}) </h5>
                                </div>


                                <div className="row collegeDetails">
                                    <div className="col-12 pt-3 pb-3 pl-4">
                                        <p>{collegesLike.Enrollment}</p>
                                        <p>{collegesLike.Control}</p>
                                        <p>{collegesLike.Setting}</p>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="row no-gutters pt-3">
                                    <div className="col-auto pr-2" style={{overflowWrap:'anywhere'}}>
                                        <div className="d-flex hoverStyle">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/globe.svg"} alt="" />
                                            {collegesLike.hasWebsite?
                                                <Link to={collegesLike.Website} target="_blank" style={{padding:'0 10px'}}>
                                                     <h6 className="pl-2 pt-2 underline">{collegesLike.Website}</h6>
                                                </Link>:<p></p>}
                                            
                                        </div>
                                    </div>

                                    <div className="col-auto pr-2" >
                                        <div className="d-flex hoverStyle">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tellme.svg"} alt="" />
                                            <Link to={`/colleges/${collegesLike.Slug}`} >
                                                <h6 className="pl-2 pt-2 underline">Tell me more</h6>
                                            </Link>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-12 col-lg-3 align-self-center">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to={`/college/${collegesLike.Slug}`}>
                                            <button className="btnTyp1">
                                                Search colleges like this
                                                <span className="first"></span>
                                                <span className="second"></span>
                                                <span className="third"></span>
                                                <span className="fourth"></span>
                                            </button>
                                        </Link>
                                    </div>
                                    <div className=" col-12 mt-2">
                                        {/* <!-- <Link href="#"> --> */}
                                        
                                            
                                            <button className="btnTyp2"  onClick={() => handelAddBackpack(collegesLike._id)}>
                                                <img src={projectUrl.hostUrl+"assets/img/icon/plusIcon.svg"} className="mr-2" alt=""/>backpack
                                            <span className="first"></span>
                                            <span className="second"></span>
                                            <span className="third"></span>
                                            <span className="fourth"></span>
                                             </button>
                                       
                                        {/* <!-- </Link> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>



                        </div>
                    ))}
                    

                </div>
            </div>
        </div>
        </section>
        
        :

        <section className="similarCollege mt-2">
        <div className="container">
            <img src={projectUrl.hostUrl+"assets/img/icon/starIcon.svg"} width="100%" className="mystart" alt="" />
            <img src={projectUrl.hostUrl+"assets/img/icon/linetera.svg"} width="100%" className="myline" alt="" />
            <div className="row pt-5 pb-5">

                <div className="col-12 col-lg-6 pt-3 pb-3">
                    <h2> Experts Recommend</h2>
                    {(apiData.data).map((expertdata, index)=>(
                        <div key={expertdata._id} className={`collegesCard ${index % 2 === 0 ? 'bg-white' : 'bg-secondColor'} p-4 mt-4`}>
                            <div className="row">
                                {/* <!-- <div className="col-2">
                                    <img src="/assets/img/content-img/collge_Icon.svg" width="100%" alt="">
                                </div> --> */}
                                <div className="col-12 pt-3">
                                    <h4>{expertdata.Name} ({expertdata.State}) </h4>
                                </div>
                            </div>
                             {/* <!-- justify-content-md-center --> */}
                            <div className="row  pt-3">
                                <div className="col-auto">
                                    <div className="d-flex hoverStyle">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/globe.svg"} alt="" />

                                        {expertdata.hasWebsite?
                                            <Link to={expertdata.Website} target="_blank">
                                            <h6 className="pl-2 pt-2 underline">{expertdata.Website}</h6>
                                        </Link>: <p></p>}
                                        
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="d-flex hoverStyle">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/tellme.svg"} alt="" />
                                        <Link to={`/colleges/${expertdata.Slug}`} >
                                            <h6 className="pl-2 pt-2 underline">Tell me more</h6>
                                        </Link>
                                    </div>
                                </div>




                            </div>
                            <div className="row collegeDetails">
                                <div className="col-lg-6 col-12 pt-3 pb-3 pl-4">
                                    <p>{expertdata.Enrollment}</p>
                                    <p>{expertdata.Control}</p>
                                    <p>{expertdata.Setting}</p>
                                </div>
                                <div className="col-lg-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-12 pt-2">
                                    <Link to={`/college/${expertdata.Slug}`}>
                                        <button className="btnTyp1">
                                            Search colleges like this
                                            <span className="first"></span>
                                            <span className="second"></span>
                                            <span className="third"></span>
                                            <span className="fourth"></span>
                                        </button>
                                        {/* <!-- <button className="btn">
                                            Search colleges like this
                                        </button> --> */}
                                    </Link>
                                </div>
                                <div className="col-lg-6 col-12 pt-2">
                                   
                                        <button className="btnTyp2" onClick={() => handelAddBackpack(expertdata._id)}>
                                            <img src={projectUrl.hostUrl+"assets/img/icon/plusIcon.svg"} className="mr-2" alt=""/>backpack
                                            <span className="first"></span>
                                            <span className="second"></span>
                                            <span className="third"></span>
                                            <span className="fourth"></span>
                                        </button>

                                    
                                </div>
                            </div>
                        </div>
                    ))}
                    
                    
                    
                </div>

                <div className="col-12 col-lg-6 pt-3 pb-3">
                    <h2>A.I. Recommends</h2>
                    {(apiData.aidata).map((genarated, index)=>(
                        <div key={genarated._id} className={`collegesCard ${index % 2 === 0 ? 'bg-secondColor': 'bg-white'} p-4 mt-4`}>
                            <div className="row">
                                {/* <!-- <div className="col-2">
                                    <img src="/assets/img/content-img/collge_Icon.svg" width="100%" alt="">
                                </div> --> */}
                                <div className="col-12 pt-3">
                                    <h4>
                                        {genarated.Name} ({genarated.State})
                                    </h4>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-auto">
                                    <div className="d-flex hoverStyle">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/globe.svg"} alt="" />
                                        {genarated.hasWebsite?
                                            <Link to={genarated.Website} target='_blank'>
                                                <h6 className="pl-2 pt-2 underline">{genarated.Website}</h6>
                                            </Link>:<p></p>}
                                        
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="d-flex hoverStyle">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/tellme.svg"} alt="" />
                                        <Link to={`/colleges/${genarated.Slug}`}>
                                            <h6 className="pl-2 pt-2 underline">Tell me more</h6>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row collegeDetails">
                                <div className="col-lg-6 col-12 pt-3 pb-3 pl-4">
                                    <p>{genarated.Enrollment}</p>
                                    <p>{genarated.Control}</p>
                                    <p>{genarated.Setting}</p>
                                </div>
                                <div className="col-lg-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-12 pt-2">
                                    <Link to={`/college/${genarated.Slug}`}>
                                        <button className="btnTyp1">
                                            Search colleges like this
                                            <span className="first"></span>
                                            <span className="second"></span>
                                            <span className="third"></span>
                                            <span className="fourth"></span>
                                        </button>
                                        {/* <!-- <button className="btn">
                                            Search colleges like this
                                        </button> --> */}
                                    </Link>
                                </div>
                                <div className="col-lg-6 col-12 pt-2">
                                    
                                        <button className="btnTyp2" onClick={() => handelAddBackpack(genarated._id)}>
                                            <img src={projectUrl.hostUrl+"assets/img/icon/plusIcon.svg"} className="mr-2" alt="" />backpack
                                            <span className="first"></span>
                                            <span className="second"></span>
                                            <span className="third"></span>
                                            <span className="fourth"></span>
                                        </button>
                                   
                                </div>
                            </div>
                        </div>
                    ))}

                    
                    
                </div>



            </div>
        </div>
        </section>
        }
    </>
  )
}

export default CollegesLikeThisBody