import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, } from 'react-router-dom';
import projectUrl from './Url';
import topbar from 'topbar';
import $ from 'jquery';

import GoogleTranslate from './GoogleTranslate';

function TopBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [isTtsEnabled, setIsTtsEnabled] = useState(localStorage.getItem('isTtsEnabled') === 'true');
  const lastSpokenElement = useRef(null); 

  //Used for google translate
  const [language, setLanguage] = useState('English');
  
  const navigate = useNavigate();
  const showOverlay = () => {
    document.getElementById('loadingOverlay').style.display = 'flex';
  };
  
  const hideOverlay = () => {
    document.getElementById('loadingOverlay').style.display = 'none';
  };

  useEffect(() => {
    topbar.show();
    showOverlay();
    const loggedIn = localStorage.getItem('loggedIn') === 'true';
    // console.log(loggedIn);
    const firstName = localStorage.getItem('first_name');
    setIsLoggedIn(loggedIn);
    if (loggedIn && firstName) {
      setFirstName(firstName);
    }
    topbar.hide();
    hideOverlay();




    //Used for google translate
    const savedLang = localStorage.getItem('lang') || 'English';
    setLanguage(savedLang);
    //loadGoogleTranslate();

    // Cleanup function
    return () => {
      const script = document.getElementById('google-translate-script');
      if (script) {
        document.body.removeChild(script);
      }
    };



  }, []);

  const handleLogout = () => {
    $.confirm({
      title: 'Confirm!',
      content: 'Do you want to logout?',
      type:'red',
      buttons: {
          Logout :{
            btnClass: 'btn-red',
            action : function () {
              localStorage.removeItem('token');
              localStorage.removeItem('first_name');
              localStorage.removeItem('Email');
              localStorage.removeItem('loggedIn');
              setIsLoggedIn(false);
              navigate('/'); // Adjust if needed, to redirect user to the homepage or login page
            },
          },
          cancel: function () {
             
          },
          
      }
  });

  };
  
  const toggleTts = () => {
    const newTtsEnabledState = !isTtsEnabled;
    setIsTtsEnabled(newTtsEnabledState);
    localStorage.setItem('isTtsEnabled', newTtsEnabledState.toString());
  };

  useEffect(() => {

    
    const speakText = (text, target) => {
      if (!text || (lastSpokenElement.current === target)) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.rate = 1.5;

      const lang = localStorage.getItem("lang");
      if(lang === 'es'){
        const language = "es-ES";
        utterance.lang = language;
      }
      else if(lang === 'zh-CN'){
        const language = "zh-CN";
        utterance.lang = language;
      }

      speechSynthesis.speak(utterance);
      lastSpokenElement.current = target;
      setTimeout(() => (lastSpokenElement.current = null), 500);
    };

    const eventHandler = (event) => {
      if (!isTtsEnabled) return;

      // Ensure event.target is an element before calling .closest
      if (!(event.target instanceof Element)) return;

      let targetElement = event.target.closest('p, h1, h2, h3, h4, h5, h6, input[placeholder], li, button, label, a');
      if (targetElement && (!lastSpokenElement.current || !lastSpokenElement.current.contains(targetElement))) {
        if (targetElement.tagName.toLowerCase() === 'input' && targetElement.placeholder) {
          speakText(targetElement.placeholder, targetElement);
        } else {
          speakText(targetElement.textContent || targetElement.alt, targetElement);
        }
      }
    };

    document.addEventListener('mouseenter', eventHandler, true);
    document.addEventListener('mouseleave', () => speechSynthesis.cancel(), true);

    return () => {
      document.removeEventListener('mouseenter', eventHandler, true);
      document.removeEventListener('mouseleave', () => speechSynthesis.cancel(), true);
    };
  }, [isTtsEnabled]);




  const handleLanguageChange = (lang) => {
    //const selectedLanguage = e.target.value;
    console.log(lang);
    const selectedLanguage = lang;
    setLanguage(selectedLanguage);
    localStorage.setItem('lang', selectedLanguage);
    translateLanguage(selectedLanguage);
  };

  const translateLanguage = (lang) => {
   // window.googleTranslateElementInit();
    setTimeout(() => {
      const iframe = document.querySelector('.goog-te-gadget .goog-te-combo');
      console.log(iframe);

      if (iframe) {
        if(lang === 'es'){
          iframe.value = lang;
          const event = new Event('change');
          iframe.dispatchEvent(event);
          setTimeout(()=>{
            iframe.value = lang;
            const event = new Event('change');
            iframe.dispatchEvent(event);
          },10)
        }
        else{
          iframe.value = lang;
          const event = new Event('change');
        // iframe.dispatchEvent(event);
          iframe.dispatchEvent(event);
        }
        



      }
    }, 500);
  };



// console.log("Wow this page load every time");

  return (
    <>
      <div className="top"></div>
      <div className="head-menu mb-0 " style={{position:"absolute", width:"100%"}}>
        <div className="menuFix">
          <div className="container">
            <div className="row no-gutters">
              {/* Language group, main-logo omitted for brevity */}
              <div className="col-6 col-md-4 col-lg-4 order-1 order-lg-1 language">
                  {/* <div id="google_translate_element" style={{ display: 'none' }}></div>  */}
                  <GoogleTranslate />
                  <ul className="">
                    <li><Link onClick={()=> handleLanguageChange('en')}   className="notranslate" >EN</Link></li>
                    <li><Link onClick={()=> handleLanguageChange('es')}  className="notranslate" >ES</Link></li>
                    <li><Link onClick={()=> handleLanguageChange('zh-CN')}  className="notranslate" >中文</Link></li>
                  </ul>
                  <select name="language" id="language" value={language} onChange={(e) => handleLanguageChange(e.target.value)} className="notranslate d-md-none">
                    <option value="en">EN</option>
                    <option value="es">ES</option>
                    <option value="zh-CN">中文</option>
                  </select>

                </div>

                {/* <!-- main-logo --> */}

                <div className="col-12 col-md-12 col-lg-4 order-3 order-lg-2 pt-1 pt-md-0 logo-group">
                  <div className="d-flex justify-content-center">
                    <Link to={'/'}><img src={`${projectUrl.hostUrl}/assets/img/logo.svg`} alt="" /></Link>
                  </div>
                </div>
              <div className="col-6 col-md-8 col-lg-4 order-2 order-lg-3 authenticate-group">
                <div className="d-flex justify-content-end">
                  {isLoggedIn ? (
                    <>
                        <div className="custom-dropdown dropdown">
                        <button className="btn bg-transparent custom-btn">
                          HI! {firstName}<span className="chevron-custom"></span>
                        </button>
                        <div className="custom-dropdown-menu">
                          <Link to="/backpack" className="dropdown-item">Backpack</Link>
                          <Link to={'/delete-account'} className="dropdown-item" >Delete Account</Link>
                        </div>
                      </div>
                      <button className="btn bg-transparent" onClick={handleLogout}>LOG OUT</button>
                    </>
                  ) : (
                    <>
                      <Link to={projectUrl.hostUrl + "login"}>
                        <button className="btn bg-transparent custom-btn button-3">
                          <div id="circle"></div>
                          SIGN UP
                        </button>
                      </Link>
                      <Link to={projectUrl.hostUrl + "login"}>
                        <button className="btn bg-transparent">LOG IN</button>
                      </Link>
                    </>
                  )}
                  <Link to="#" className="pt-lg-2 pt-md-2 text-dark pl-md-1 audio_en d-none d-md-block d-lg-block" style={{ fontSize: '25px' }} onClick={toggleTts}>
                  <i className={`fas fa-volume-up ${isTtsEnabled ? 'text-primary' : ''}`}></i>
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopBar;
