import axios from "axios";
import topbar from "topbar";
import $ from 'jquery';

const showOverlay = () => {
  document.getElementById('loadingOverlay').style.display = 'flex';
};

const hideOverlay = () => {
  document.getElementById('loadingOverlay').style.display = 'none';
};

// Base function to handle requests
const makeRequest = async (method, apiEndpoint, config = {}, data = {}) => {

 
  topbar.show(); // Show the loading indicator
  showOverlay();

  // Default headers configuration
  const headersConfig = {
    headers: {
      'x-api-key': '8c655bfc-a035-4b4c-af1a-39cf1a10bcdb',
      ...config.headers,
    },
  };

  const requestConfig = {
    ...config,
    ...headersConfig,
    method: method,
  };
  // console.log(requestConfig);
  if (['post', 'put', 'patch', 'delete'].includes(method) && Object.keys(data).length) {
    requestConfig.data = data;
  }

  try {
    const response = await axios(apiEndpoint, requestConfig);

    topbar.hide(); // Hide the loading indicator after fetching data
    hideOverlay();

    return response.data;
  } catch (error) {
    // console.error('Failed to fetch data:', error.message);

    topbar.hide();
    hideOverlay();

    if(error.response && (error.response.status === 401)){
      console.log(error.response.status);
    }
    else if (error.response && error.response.data && error.response.data.message) {
      $.alert({
          title: 'Warning!',
          content: error.response.data.message, // Assuming the message is a string
          buttons: {
              ok: {
                  text:'OK',
                  btnClass: 'btn-green'
              }
          }
      });
  } else {
      // Fallback error message if the expected structure isn't found
      $.alert({
          title: 'Error!',
          content: 'An unexpected error occurred.',
          buttons: {
              ok: {
                  btnClass: 'btn-red'
              }
          }
      });
  }
    
    
    throw error;
  }
};










// Base function to handle requests
const makeRequestForSearch = async (method, apiEndpoint, config = {}, data = {}) => {

 

  // Default headers configuration
  const headersConfig = {
    headers: {
      'x-api-key': '8c655bfc-a035-4b4c-af1a-39cf1a10bcdb',
      ...config.headers,
    },
  };

  const requestConfig = {
    ...config,
    ...headersConfig,
    method: method,
  };
  // console.log(requestConfig);
  if (['post', 'put', 'patch', 'delete'].includes(method) && Object.keys(data).length) {
    requestConfig.data = data;
  }

  try {
    const response = await axios(apiEndpoint, requestConfig);

   
    return response.data;
  } catch (error) {
    // console.error('Failed to fetch data:', error.message);
   

    if(error.response && (error.response.status === 401)){
      console.log(error.response.status);
    }
    else if (error.response && error.response.data && error.response.data.message) {
      $.alert({
          title: 'Warning!',
          content: error.response.data.message, // Assuming the message is a string
          buttons: {
              ok: {
                  text:'OK',
                  btnClass: 'btn-green'
              }
          }
      });
  } else {
      // Fallback error message if the expected structure isn't found
      $.alert({
          title: 'Error!',
          content: 'An unexpected error occurred.',
          buttons: {
              ok: {
                  btnClass: 'btn-red'
              }
          }
      });
  }
    
    
    throw error;
  }
};

// Define the fetchData object with method-specific functions
const fetchData = {
  get: (apiEndpoint, config = {}) => makeRequest('get', apiEndpoint, config),
  post: (apiEndpoint, config = {}, data = {}) => makeRequest('post', apiEndpoint, config, data),
  delete: (apiEndpoint, config = {}, data = {}) => makeRequest('delete', apiEndpoint, config, data),
  fetch:(apiEndpoint, config = {}, data = {}) => makeRequestForSearch('post', apiEndpoint, config, data),
  // You can add more HTTP methods here following the same pattern
};

export default fetchData;
