
import './App.css';
import Index from './screens/Index/IndexPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './screens/Login/LoginPage';
import AboutPage from './screens/About/AboutPage';
import CollegeStudentVideos from './screens/CollegeStudentVideos/CollegeStudentVideos';
import ContactPage from './screens/Contact/ContactPage';
import MethodologyPage from './screens/Methodology/MethodologyPage';
import SchoolPage from './screens/School/SchoolPage';
import TermsConditionPage from './screens/TermsCondition/TermsConditionPage';
import SearchCollegePage from './screens/SearchCollege/SearchCollegePage';
import TellMeAboutPage from './screens/TellMeAbout/TellMeAboutPage';
import BackPackPage from './screens/BackPack/BackPackPage';
import CollegeDetailsPage from './screens/CollegeDetailsPage/CollegeDetailsPage';
import CollegesLikeThisPage from './screens/CollegesLikeThis/CollegesLikeThisPage';
import RegistrationPage from './screens/Registration/RegistrationPage';
import DeleteAccountPage from './screens/DeleteAccount/DeleteAccountPage';
import 'jquery-confirm';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import BackpackEmail from './screens/BackPackEmailView/BackpackEmail';
import ScrollToTop from './screens/ProjectComponents/ScrollToTop'
import TopBar from './screens/ProjectComponents/TopBar';
// import NotFoundPage from './screens/NotFound/NotFoundPage';


function App() {
  return (
    <>
      {/* <GoogleTranslate /> */}
      <Router>
      <ScrollToTop />
          {/* <Language/> */}
          <TopBar/>
          <Routes>
              <Route path='/' exact element={<Index/>}/>
              <Route path='/login' exact element={<LoginPage/>}/>
              <Route path='/about' exact element={<AboutPage/>}/>
              <Route path='/contact' exact element={<ContactPage/>}/>
              <Route path='/college-student-videos' exact element={<CollegeStudentVideos/>}/>
              <Route path='/methodology' exact element={<MethodologyPage/>}/>
              <Route path='/school' exact element={<SchoolPage/>}/>
              <Route path='/terms-condition' exact element={<TermsConditionPage/>}/>
              <Route path='/search-college' exact element={<SearchCollegePage/>}/>
              <Route path='/tell-me-about' exact element={<TellMeAboutPage/>}/>
              <Route path='/backpack' exact element={<BackPackPage/>}/>
              <Route path='/backpack/user/:user_id' exact element={<BackpackEmail/>}/>
              <Route path='/colleges/:slug' exact element={<CollegeDetailsPage/>}/>
              <Route path='/college/:slug' exact element={<CollegesLikeThisPage/>}/>
              <Route path='/registraion' exact element={<RegistrationPage/>}/>
              <Route path='/delete-account' exact element={<DeleteAccountPage/>}/>
              {/*<Route path="*" element={<NotFoundPage />} />*/}  
          </Routes>
      </Router>
      
    </>
  );
}

export default App;
