import React, { useEffect, useState } from 'react'
import AboutBanner from './Components/AboutBanner'
import AboutBody from './Components/AboutBody'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';
// import SEOHelmet from '../ProjectComponents/SEOHelmet'

function AboutPage() {
  // const page= 'about';
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('about');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);
  return (
    <>
        {/* topbar */}
        {/* <SEOHelmet page={page} /> */}
          <div className='bg-About main'>
            {/* <TopBar/> */}
            <AboutBanner/>
          </div>

        {/* body */}
        <AboutBody/>
        <IndexFooter/>

    </>
  )
}

export default AboutPage