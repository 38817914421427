import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import projectUrl from '../../ProjectComponents/Url';
import CustomModal from './VdPlayer';
import fetchData from '../../ProjectComponents/GlobalFetchApi';
// import { fetchData } from '../../ProjectComponents/GlobalFetchApi';


function IndexVideoList() {
  const [videos, setVideos] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null); // State to keep track of the active video
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
          const data = await fetchData.get(projectUrl.homeSectionVideos);
          setVideos(data.data); // Assuming the response structure
      } catch (error) {
          // Error handling can be done here if necessary
      }
  };

    fetchVideos();
  }, []);

  const showModal = (video) => {
    setActiveVideo(video); // Set the active video
    setModalShow(true); // Show the modal
  };

  const handleClick = (video) => {
    if (video.type === "Video") {
      showModal(video);
    } else {
      // Handle other types differently, for example:
      // here we can use different url for different card
      // console.log("This item is not a video.");
      navigate('/'+video.videoId);
    }
  };

  return (
    <>
      <section className="video_card pt-4">
        <div className="container">
          <h2>Start here</h2>
          <div className="row no-gutters">
            {videos.map((video) => (
              <div key={video._id} className="col-12 col-md-4 col-lg-2">
                <div className="v_card play_js_video">
                  <Link 
                    to="#" // Using "#" as placeholder, adjust as needed
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      handleClick(video);
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <img src={`${projectUrl.hostUrl}/assets/thumbnail/${video.thumbnail}`} width="100%" className="lazy" alt={video.title} />
                      </div>
                      <div className="col-12">
                        <h6 className="v_card_title pt-2">{video.title}</h6>
                      </div>
                    </div>
                    {video.length && <h5 className="badge badge-pill badge-dark">{video.length}</h5>}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Render CustomModal once, outside the map function */}
      {activeVideo && (
        <CustomModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={activeVideo.title} // Use activeVideo state to pass props
          videoId={activeVideo.videoId}
        />
      )}
    </>
  );
}

export default IndexVideoList;
