import React, {useState, useEffect} from 'react'
import projectUrl from '../../ProjectComponents/Url'
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import { useNavigate} from 'react-router-dom';
import MapWithMarker from '../../ProjectComponents/MapWithMarker';

function TellMeAboutBody() {
    const [tellMeAboutValue, setTellMeAboutValue] = useState('');
    const [colleges, setColleges] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchColleges = async () => {
            if (tellMeAboutValue.length >= 3) {
                
                const data = { 'Name': tellMeAboutValue }; // Adjust according to your API's expected request body

                try {
                    const response = await fetchData.post(projectUrl.getCollegeSuggestions, {}, data);
                    setColleges(response.data); // Assuming the response structure
                    console.log(response.data);
                } catch (error) {
                    console.error('Failed to fetch colleges:', error);
                }
            } else {
                setColleges([]); // Clear the list if input is less than 3 characters
            }
        };

        fetchColleges();
    }, [tellMeAboutValue]);

    const handleTellMeAboutChange = (e) => {
        const value = e.target.value;
        setTellMeAboutValue(value);
    };
    const handleItemClick = (slug) => {
        navigate(`/colleges/${slug}`); // Navigate to the college page with the slug
    };
    return (
        <>
            <section className="tellMeAbout pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 align-self-center pb-4 search_cl_div">
                            <h2>Tell Me About</h2>
                            <p className="pt-3">Get information about specific colleges and universities.</p>

                            <div className="input_field">
                                <div className="row">
                                    <div className="col-1 col-md-2 col-lg-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/collegeIcon.svg"} alt="" />
                                    </div>
                                    <div className="col-11 col-md-10 col-lg-11">
                                            <input
                                                onChange={handleTellMeAboutChange}
                                                type="search"
                                                className="pl-2 college_name"
                                                data-type="tell-me-about"
                                                autoComplete="off"
                                                name="tellMeAbout"
                                                id="clg_name"
                                                placeholder="Enter a college name here"
                                                value={tellMeAboutValue}
                                            />
    { /*<input type="search" name="searchCollege" id="searchCollege" data-type="tell-me-about" placeholder="College or university name " className="college_name" />*/}
                                    </div>
                                </div>
                            </div>

                            {colleges.length > 0 && (
                                <div className="row no-gutters justify-content-center">
                                    <div className="col-11">
                                        <div className="listItem pl-3 pr-3">
                                            <ul className="dropdown_menu--animated dropdown_menu-7 bg-dark suggestion_box" >
                                                {colleges.map((college) => (
                                                    <li className='p-3 border' key={college._id} onClick={() => handleItemClick(college.Slug)}>
                                                        {college.Name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}

                           {/*} <div className="row no-gutters justify-content-center">
                                <div className="col-11">
                                    <div className="listItems">
                                        <ul className=" dropdown_menu--animated dropdown_menu-7 suggestion_box" style={{display: 'none'}}>

                                        </ul>
                                    </div>
                                </div>
                             </div>*/}

                        </div>
                        <div className="col-12 col-md-6">
                            <div className="Map_finding">
                            <div className='border-2'>
                                <MapWithMarker lat={'38.907523443797146'} lng={'-77.03114996185272'} tooltip={'Washington'} />
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TellMeAboutBody