import React, { useEffect, useState } from 'react'
import IndexBanner from './Components/IndexBanner'
import IndexCarousal from './Components/IndexCarousal'
import IndexSearch from './Components/IndexSearch'
import IndexVedioList from './Components/IndexVedioList'
import IndexBenifit from './Components/IndexBenifit'
import IndexSign from './Components/IndexSign'
import IndexFooter from './Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData'
import { updateMetaTags } from '../ProjectComponents/updateMetaTags'
// import TopBar from '../ProjectComponents/TopBar'
// import SEOHelmet from '../ProjectComponents/SEOHelmet'

function Index() {
  // const page = 'index';
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('index');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);
  
  return (
    <>
    {/* <SEOHelmet page={page} /> */}
        {/* <!-- Banner--> */}
        <div className='bg-main main'>
          {/* <TopBar/> */}
          <IndexBanner/>
        </div>
        {/* Carousal */}
        <IndexCarousal/>
        {/* search component */}
        <IndexSearch/>
        {/* video components */}
        <IndexVedioList/>
        {/* benefits */}
        <IndexBenifit/>
        {/* sign */}
        <IndexSign/>
        {/* footer */}
        <IndexFooter/>
    </>
  )
}

export default Index