import React, { useEffect, useState } from 'react'
import TermsConditionBanner from './Components/TermsConditionBanner'
import TermsConditionBody from './Components/TermsConditionBody'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';

function TermsConditionPage() {

  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('terms-of-use');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);

  // const page = 'terms-of-use'
  return (
    <>
       
        <div className='bg-About main'>
          {/* <TopBar/> */}
          <TermsConditionBanner/>
        </div>
        <TermsConditionBody/>
        <IndexFooter/>
    </>
  )
}

export default TermsConditionPage