import React from 'react'
import projectUrl from '../../ProjectComponents/Url'

function AboutBody() {
  return (
    <>
        {/* <!-- -------about-collegeslike --> */}

<section className="AboutCollegesLike pt-5">
    <div className="container">
        <h1>About CollegesLike</h1>

        <p className="pt-3">There are over 3,400 colleges and universities in America. It's really difficult to know what they're like and what might be a good fit for you. So applicants have been making haphazard decisions about colleges. That’s not good for students or higher education.</p>
        <p className="pt-3">Several of the staff at CollegesLike are former Admission Vice Presidents and officers at Top 10 colleges and universities. We wanted to created a set of free, student-centered tools to cut through the noise and help you quickly focus on a short list of colleges that match your interests.</p>
        <p className="pt-3">So we conceived of a system to help you through the process, and then we created some revolutionary tools. And we insisted that they are 100% free!</p>
        <p>With CollegesLike, you'll use science, professional expertise, and information from current college students to put together a list of colleges. It’s helpful to have professionals in your corner, especially if you have limited access to college counselors and coaches.</p>
        <p className="pt-3">In a very short period of time, you can: learn about the differences between colleges, search on individual colleges, find similarities between colleges, watch videos of current college students sharing their experiences, share your college list with your counselors, teachers, family members, and friends, and have a guide through the entire application process!</p>
    </div>
</section>

{/* <!-- ------------------our-team----------------- --> */}

<section className="OurTeam pt-5 pb-4">
    <div className="container">
        <h1 className="">Our Team</h1>
        <div className="row">

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(9).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Jeff, CEO & Co-Founder</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Jeff worked in higher education for 30+ years and was the Vice President for Admission & Financial Aid and Presidential Advisor at a top-10 liberal arts college. Jeff holds a PhD in Philosophy, and taught college classNamees for 35 semesters. Most recently, Jeff was the President of an independent school in Maryland.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(8).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Catie, Co-Founder</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Catie worked as a teacher in public and private schools for a decade, before making the shift to working in college admission.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(7).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Jonathan, Leadership Coach</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Jonathan is the former VP of Products and current Senior Advisor to the CEO of Google/Alphabet. Jonathan has been running huge technology companies (FAANG) for over 30 years. He has an MBA from the Chicago/Booth School, is the author of two best-selling books on management, and is now mentoring the CEO’s of companies that he cares about.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(6).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Dick, Consultant and Advisor</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Dick worked in higher education for over 40 years and was the Vice President for Admission and Financial Aid at a top-10 liberal arts college. Dick is now retired and serves as an advisor to the CEO, particularly on west-coast institutions and liberal arts colleges.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(5).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Bill, Consultant and Advisor</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Bill worked in higher education for over 40 years and was the Vice President for Admission and Financial Aid at some of America’s most prestigious universities. Bill is now retired and serves as an advisor to the CEO, particularly on east-coast institutions and research universities.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(4).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Arindam, Developer</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Arindam takes our information and turns it into a functional website. There's a lot of programming code going on behind the scenes, and almost all of it was written by Arindam.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(3).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Kay, Legal Consultant</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Kay wrote the Terms of Service and has proofread many versions of CollegesLike.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(2).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Ann, Legal Consultant</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Ann helped us get the Copyright and Trademark applications completed.</p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pt-4">
                <div className="row">
                    <div className="col-3">
                        <img src={projectUrl.hostUrl+"assets/img/content-img/Avtar%20(1).png"} width="100%" alt=""/>
                    </div>
                    <div className="col-9 align-self-center">
                            <h5 className="font-weight-bold">Faye, Graphic Artist</h5>
                    </div>
                    <div className="col-12 pt-4">
                        <p>Faye designed the CollegesLike logo, and runs a gallery for marginalized artists in Glendale, California.</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>
    </>
  )
}

export default AboutBody