import React, { useEffect, useState } from 'react'
import SchoolBanner from './Components/SchoolBanner'
import SchoolBody from './Components/SchoolBody'
import SchoolAuth from './Components/SchoolAuth'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData'
import { updateMetaTags } from '../ProjectComponents/updateMetaTags'
// import TopBar from '../ProjectComponents/TopBar'

function SchoolPage() {

  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('school');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);

  // const page = 'school'
  return (
    <>
        
        <div className='bg-main main'>
          {/* <TopBar/> */}
          <SchoolBanner/>
        </div>
        {/* body */}

        <SchoolBody/>
        <SchoolAuth/>

        {/* footer */}
        <IndexFooter/>
    </>
  )
}

export default SchoolPage