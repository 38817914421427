import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import projectUrl from '../../ProjectComponents/Url'
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import { Modal } from 'react-bootstrap';
import $ from 'jquery'


function LoginBody() {
  const [selectedUserType, setSelectedUserType] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Use useHistory hook for navigation
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChangeForgetEmail = (event) => {
    setInputValue(event.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const forgetPasswordSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const emailData = {
      email: inputValue, // Assuming inputValue is the state tracking the input field
    };

    try {
      // Assuming `globalApiFunction` is your API call function
     const response= await fetchData.post(projectUrl.forgetPassword, {}, emailData);
      // alert('Submission successful! Password will be sent to your email.');
      $.alert({
        title:'Success',
        content: response.message,
        type:'green'});
      handleClose(); // Close the modal on success
    } catch (error) {
      // alert('Error submitting the form.'+error.message);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedUserType(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Navigate to the next screen and pass the selectedUserType
    // You might need to adjust this part depending on how you're handling routing and data passing
    if(!selectedUserType){
      $.alert({
        title:'Warning !',
        content: 'Please Select any one type of user',
        type:'red',
        buttons :{
          Ok :{
            text:'OK',
            btnClass:'btn-red'
          }
        }
        
      });
    }else{
      navigate('/registraion', { state: { userType: selectedUserType } });
    }
    
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = projectUrl.login; // Adjust the URL based on your actual API endpoint
    const loginData = {
      email: email,
      password: password,
    };

    try {
      const response = await fetchData.post(apiEndpoint, {}, loginData); // Adjust based on how your fetchData function is implemented
      if (response.loggedIn) {
        // Assuming the response structure matches your example
        localStorage.setItem('token', response.token);
        localStorage.setItem('first_name', response.first_name);
        localStorage.setItem('Email', response.data.Email);
        localStorage.setItem('loggedIn', response.loggedIn.toString());
        // Navigate to the dashboard or home page after successful login
        navigate('/'); // Adjust the route as necessary
        window.location.reload();
      } else {
        $.alert({
          title:'Warning !',
          content: 'Login failed: ' + response.message,
          type:'red',
          buttons :{
            Ok :{
              text:'OK',
              btnClass:'btn-red'
            }
          }
          
        });
         // Show error message from the response
      }
    } catch (error) {
      console.error('Login error:', error);
      $.alert({
        title:'Warning !',
        content: 'Login failed: ' + error.response.message,
        type:'red',
        buttons :{
          Ok :{
            text:'OK',
            btnClass:'btn-red'
          }
        }
        
      });
    }
  };
  return (
    <>
        <div className="hero-area pt-1 pt-sm-1">
      
      <div className="row no-gutters">
        <div className="col-md-6 col-12 text-center pt-5">
            <img src="assets/img/content-img/signupHED.png" width={'300px'} alt=""/>
            <h1 className="pt-3 pb-3">New Users</h1>
            <div className="row no-gutters justify-content-center">
                <form onSubmit={handleSubmit}>
                  <div className="col-12 col-11 text-left">

                    <div className="form-check mt-4">
                      <input className="form-check-input form_check_box" name="User_Type" type="radio" value="Current school student" id="scnhool" onChange={handleRadioChange}/>
                      <label className="form-check-label bold" for="scnhool"> Current school student.</label>
                    </div>
                    <div className="form-check mt-2">
                      <input className="form-check-input form_check_box" type="radio" onChange={handleRadioChange} name="User_Type" value="Current college student (contemplating transfer)" id="fb"/>
                      <label className="form-check-label bold" for="fb"> Current college student (contemplating transfer).
                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input className="form-check-input form_check_box" type="radio" onChange={handleRadioChange} name="User_Type" value="Not currently enrolled but looking to begin college" id="fr"/>
                      <label className="form-check-label bold" for="fr"> Not currently enrolled but looking to begin
                        college.</label>
                    </div>
                    <div className="form-check mt-2">
                      <input className="form-check-input form_check_box" type="radio" onChange={handleRadioChange} name="User_Type" value="Parent or Guardian" id="fs"/>
                      <label className="form-check-label bold" for="fs">Parent or Guardian.
                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input className="form-check-input form_check_box" type="radio" onChange={handleRadioChange} name="User_Type" value="School Counselor, Faculty, or Staff Member" id="bs"/>
                      <label className="form-check-label bold" for="bs">School Counselor, Faculty, or Staff Member.

                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input className="form-check-input form_check_box" type="radio" onChange={handleRadioChange} value="College Staff or Researcher" name="User_Type" id="bg"/>
                      <label className="form-check-label bold" for="bg">College Staff or Researcher.

                      </label>
                    </div>

                  </div>
                  <div className="col-12 pt-4 pb-5">
                    <button type="submit" className="btnTyp2  w-100 " >
                    CONTINUE
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                  </button>
                  </div>
                </form>

            </div>
        </div>


        <div className="col-md-6 col-12 bg-login pt-5 text-center">
            <img src={projectUrl.hostUrl+"assets/img/content-img/loginHED.png"} width="300px" className="" alt=""/>
            <h1 className="pt-3 pb-5">Registered Users</h1>
            <form onSubmit={handleLoginSubmit}>
                          <div className="row no-gutters justify-content-center">
                <div className="col-11 col-lg-9 col-xl-8">
                  <div className="inputDiv">
                        <div className="row no-gutters">
                          <div className="col-lg-1 col-2 col-md-2 iconLeft">
                            <img src={projectUrl.hostUrl+"assets/img/icon/envelope.svg"} alt=""/>
                          </div>
                          <div className="col-lg-11 col-10 col-md-10 p-2 pt-3">
                          <input type="email" className="InputType" name="emails" id="emails" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
                          </div>
                        </div>
                      
                  </div>
                </div>
            </div>

            <div className="row no-gutters justify-content-center pt-3">
              <div className="col-11 col-lg-9 col-xl-8">
                <div className="inputDiv">
                    
                      <div className="row no-gutters">
                        <div className="col-lg-1 col-2 col-md-2 iconLeft">
                          <img src={projectUrl.hostUrl+"assets/img/icon/locker.svg"} alt=""/>
                        </div>
                        <div className="col-lg-11 col-10 col-md-10 p-2 pt-3">
                            <input type="password" className="InputType" name="passwords" id="passwords" placeholder="Enter your password" value={password} onChange={handlePasswordChange} />
                        </div>
                      </div>
                    
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-center pt-3 font-weight-bold">
                  <Link to={'#'} onClick={handleShow} className="font-italic float-right">I forgot my password.</Link>
                </div>
              </div>
              <div className="col-5 pt-4 pb-5">
                  <button type="submit" className="btnTyp2 w-100 ">
                    LOG IN
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                  </button>
              </div>

              
          </div>

          </form>
        </div>
        
      </div>
    
  </div>
  <Modal show={show} onHide={handleClose} centered backdrop="static">
      <form onSubmit={forgetPasswordSubmit}>
        <Modal.Header className='bg-dark text-white'>
          <Modal.Title>We will send password in your email</Modal.Title>
          <button type="button" className="close" onClick={handleClose} aria-label="Close">
          <span className='text-white' aria-hidden="true">&times;</span>
        </button>
        </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
              <div className="forgetDiv">
              <div className="row no-gutters">
                        <div className="col-lg-1 col-2 col-md-2 iconLeft">
                          <img src={projectUrl.hostUrl+"assets/img/icon/envelope.svg"} alt=""/>
                        </div>
                        <div className="col-lg-11 col-10 col-md-10 p-2 pt-3">
                        <input
                            type="email"
                            className="forgetInput"
                            value={inputValue}
                            onChange={handleChangeForgetEmail}
                            placeholder="Enter your email"
                          />
                        </div>
                      </div>
                
              </div>
            </Modal.Body>
        <Modal.Footer>
                  <button type="submit" className="btnTyp2 px-5 py-2" style={{borderRadius:'10px'}}>
                    Submit
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                  </button>
          
          
        </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default LoginBody