import React from 'react'
import BackPackBanner from './Components/BackPackBanner'
import IndexFooter from '../Index/Components/IndexFooter'
// import TopBar from '../ProjectComponents/TopBar'
import BackPackBody from './Components/BackPackBody'


function BackpackEmail() {
   

  return (
    <>
        {/* topbar */}
        <div className='bg-main main'>
          {/* <TopBar/> */}
          {/* <TopBar/> */}
          <BackPackBanner/>
        </div>
            <BackPackBody/>
           {/* <BackPackBody/> */}
          {/* <BackpackWithData/> */}
        <IndexFooter/>
    </>
  )
}

export default BackpackEmail