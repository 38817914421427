import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai'; // Importing close icon from react-icons

function CustomModal(props) {
  const playerRef = useRef(null);

  useEffect(() => {
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        console.log("Initializing YT.Player with videoId:", props.videoId);
        playerRef.current = new YT.Player('player', {
          height: '600px',
          width: '100%',
          videoId: props.videoId,
          playerVars: {
            // autoplay: 1, // Auto-play the video on load
            // controls: 0, // Hide the play controls
            modestbranding: 1, // Minimize YouTube branding, doesn't remove the YouTube logo but makes it smaller
            rel: 0, // Don't show related videos when playback ends
            // showinfo: 0, // Deprecated, no longer hides video info
            // loop: 1, // Loop the video
            // playlist: props.videoId, // Required for looping; set to the same video ID
            fs: 0, // Hide the full-screen button
            // cc_load_policy: 0, // Hide closed captions
            // iv_load_policy: 3, // Hide video annotations
            // autohide: 1 // Deprecated, originally auto-hid the video controls
          },
        });
      }
    };

    if (window.YT && window.YT.Player) {
      console.log("YT.Player is already available.");
      createPlayer();
    } else {
      window.onYouTubeIframeAPIReady = createPlayer;

      if (!document.getElementById('youtube-iframe-api')) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        tag.id = 'youtube-iframe-api';
        tag.onerror = () => console.error("Failed to load YouTube IFrame API script.");
        document.body.appendChild(tag);
      }
    }
  }, [props.videoId]);

  return (
    <>
      <Modal
        {...props}
        // dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="xl"
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static" // This makes the modal not close when clicking outside of it
        keyboard={false} // This prevents closing the modal with the keyboard (esc key)
        fullscreen={true}
        onEntered={() => {
          if (!playerRef.current) {
            window.onYouTubeIframeAPIReady && window.onYouTubeIframeAPIReady();
          }
        }}
      >
        <Modal.Body className='p-0 bg-dark position-relative' >
          <div id="player" className='ytplay'></div>
          {/* Custom close icon */}
          <div className='position-absolute w-100 ' style={{top:'0px', zIndex: 1049, height: '60px',background:'black'}}>
              <div variant="link" className="position-absolute bg-transparent border-dark" style={{ top: '0px', right: '0px', color: 'white', zIndex: 1050, height:'30px', cursor:'pointer' }} onClick={props.onHide}>
                <AiOutlineClose color='red' size={30} />
              </div>
          </div>
          <div className='position-absolute w-100 ' style={{bottom:'0px', zIndex: 1049, height: '45px', background:'black'}}>

          </div>
          
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomModal;
