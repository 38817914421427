import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom';
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import projectUrl from '../../ProjectComponents/Url';

function BackPackBody() {
    const [backpackData, setBackpackData] = useState([]);
    const { user_id } = useParams(); 
    useEffect(() => {
        const fetchBackpackData = async () => {
           // Retrieve the token from localStorage
          
          // console.log(token);
          try {
            const requestBody = {
                user_id : user_id
            }
            // console.log(requestBody);
            // Include the Authorization header with the token
            const response = await fetchData.post(projectUrl.receiptBackpack, {}, requestBody);
            if (response && response.data) {
              setBackpackData(response.data);
            }
          } catch (error) {
              console.error("Failed to fetch backpack data:", error);
              // Handle other errors as needed
            
          }
        };
    
        fetchBackpackData();
        
      }, [user_id]); 
      const hasData = backpackData && backpackData.length > 0;

    return (
        <>
            <section className="backpack_section pt-5 pb-5">
                <div className="container">
                    <h1>Welcome to Backpack</h1>
                    <p className="pt-3 pb-3">Store information about colleges you like and share it with others.</p>

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-5">
                        </div>
                    </div>
                    {hasData ? (
                        backpackData.map((item,bacPackIndex) => (
                          <div key={bacPackIndex} className="row no-gutters mt-3" disabled>
                            <div className="col-12 col-lg-6  ">
                              <div className="content">
                                <div className="row no-gutters backPackHeading d-flex">
                                  {/*collegeslike backpack heading start*/}
            
                                  <div className="col-6 d-flex border-right-white">
                                    <div className="heading_college p-2">
                                      <p className="text-white text-center backpack_heading_p">
                                        COLLEGES
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-6 d-flex border-right-white">
                                    <div className="heading_notes p-2">
                                      <p className="text-white text-center backpack_heading_p">
                                        NOTES
                                      </p>
                                    </div>
                                  </div>
            
                                  {/*collegeslike backpack heading end*/}
                                </div>
                                <div className="row no-gutters justify-content-center collegesDetailsRow">
                                  <div className="col-6  border-full-wh">
                                    <div className="collegeDetails pl-3 pt-4 pb-4">
                                      <Link to={`/colleges/${item.college.Slug}`} disabled>
                                        <h5 className="text-dark font-weight-bold">
                                          {item.college.Name}
                                        </h5>
                                      </Link>
                                      <p className="float-left">{item.college.State}</p>
                                      
                                    </div>
                                  </div>
            
                                  <div className="col-6  border-full-wh d-flex">
                                    <div className="college_note">
                                      <textarea
                                        disabled
                                        defaultValue={item.notes}
                                        name="note"
                                        className="notes"
                                        id="notes"
                                        cols="30"
                                        rows="4"
                                        // onChange={handleNotesChange(bacPackIndex)}
                                        placeholder="Write Some notes here"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
            
                            <div className="col-12 col-lg-6  ">
                              <div className="content">
                                <div className="row no-gutters backPackHeading d-flex">
                                  <div className="col-6 d-flex border-right-white">
                                    <div className="heading_interest p-2">
                                      <p className="text-white text-center backpack_heading_p">
                                        MY INTEREST
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-6 d-flex border-right-white">
                                    <div className="heading_chance p-2">
                                      <p className="text-white text-center backpack_heading_p">
                                        MY ADMISSION CHANCE
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="row no-gutters justify-content-center collegesDetailsRow">
                                  <div className="col-6  border-full-wh ">
                                    <div className="interest pt-3 ">
                                      {Object.entries(item.interest).map(([key, value], index) => (
                                        <div key={index} className="form-check pt-3">
                                          <input
                                          disabled
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={value}
                                            // onChange={handleInterestChange( bacPackIndex,key)}
                                            id={`${item._id}-${key}`}
                                          />
            
                                          <label
                                            className="form-check-label1"
                                            htmlFor={`${item._id}-${key}`}
                                           
                                          >
                                            {key}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
            
                                  <div className="col-6  border-full-wh">
                                    <div className="chanceRate pt-3 pb-2">
                                      {Object.entries(item.tier).map(([key, value],index) => (
                                        <div key={index} className="form-check pt-3">
                                          <input
                                          disabled
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={value}
                                            // onChange={handleAdChanceChange( bacPackIndex,key)}
                                            id={`${item._id}-tier-${key}`}
                                          />
                                          <label
                                            className="form-check-label1"
                                            htmlFor={`${item._id}-tier-${key}`}
                                          >
                                            {key}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) :(<div>No Backpack Available</div>)}
                    

                </div>

            </section >
        </>
    )
}

export default BackPackBody