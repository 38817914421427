import React, { useEffect, useState } from 'react'
import CollegeStBanner from './Components/CollegeStBanner'
import CollegeStBody from './Components/CollegeStBody'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';
// import TopBar from '../ProjectComponents/TopBar'

function CollegeStudentVideos() {
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('college-student-videos');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);
  // const page = 'college-student-videos'
  return (
    <>

        <div className='bg-findColleges main'>
          {/* <TopBar/> */}
          <CollegeStBanner/>
        </div>
        <CollegeStBody/>
        <IndexFooter/>
    </>
  )
}

export default CollegeStudentVideos