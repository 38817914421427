import React from 'react'
import BackPackBanner from './Components/BackPackBanner'
import IndexFooter from '../Index/Components/IndexFooter'
import BackpackWithData from './Components/BackpackWithData'
// import TopBar from '../ProjectComponents/TopBar'

function BackPackPage() {
  return (
    <>
        {/* topbar */}
        <div className='bg-main main'>
         
          <BackPackBanner/>
        </div>
            <BackpackWithData/>
           {/* <BackPackBody/> */}
          {/* <BackpackWithData/> */}
        <IndexFooter/>
    </>
  )
}

export default BackPackPage