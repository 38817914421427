import React, {useState} from 'react'
import projectUrl from '../../ProjectComponents/Url'
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import $ from 'jquery';
function ContactBody() {
        // State for form fields
        const [formFields, setFormFields] = useState({
            name: '',
            email: '',
            subject: '',
            message: '',
        });
    
        // Handle input changes
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormFields({
                ...formFields,
                [name]: value,
            });
        };
    
        // Handle form submission
        const handleSubmit = async (e) => {
            e.preventDefault(); // Prevent default form submission behavior
   
            const requestBody = {
                
                    name:formFields.name,
                    email:formFields.email,
                    subject:formFields.subject,
                    message:formFields.message
                
            }
    
            try {
                const response = await fetchData.post(projectUrl.contact, {}, requestBody);
                console.log(response); // Handle success response
                // Reset form fields or show a success message
                $.alert({
                    title:'Success',
                    content: response.message,
                    type:'gree',
                    buttons: {
                        
                        ok: function() {
                            setFormFields({
                                name: '',
                                email: '',
                                subject: '',
                                message: '',
                            });
                        },
                        
                    }
                })
            } catch (error) {
                console.error('Error submitting form:', error);
                // Handle error response
            }
        };
    return (
        <>
            <div className="hero-area pt-1 pb-5 pt-sm-1 pt-md-5">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-6 col-12">
                            <img src={projectUrl.hostUrl+"assets/img/content-img/contact_bn.png"} width="100%" alt="school-girl" />
                            {/* <!-- <img src="/assets/img/content-img/header-sm.svg"  alt=""> --> */}
                        </div>
                        <div className="col-lg-6 col-12 p-2 align-self-md-center align-self-start contactus_group">
                            <h1 className="text-center text-lg-left pt-3 pt-lg-0">Contact Us</h1>
                            <form  className="c_contact" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-lg-6 pt-3">
                                        <div className="Input1">
                                            <div className="row no-gutters">
                                                <div className=" col-1 pt-1 pl-0 pl-md-1">
                                                    <img src={projectUrl.hostUrl+"assets/img/icon/userIcon.svg"} className="pb-1" alt="" style={{width:'18px'}} />
                                                </div>
                                                <div className=" col-11  p-1 pl-3">
                                                    <input type="text" placeholder="Name" name="name" id="name" onChange={handleChange} value={formFields.name} required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 pt-3">
                                        <div className="Input1">
                                            <div className="row no-gutters">
                                                <div className="col-1 pt-1 pl-0 pl-md-1">
                                                    <img src={projectUrl.hostUrl+"assets/img/icon/envelope.svg"} className="" alt="" style={{width:'18px'}} />
                                                </div>
                                                <div className=" col-11 p-1 pl-3">
                                                    <input type="email" placeholder="Enter email" name="email" id="email" onChange={handleChange} value={formFields.email} required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="Input1">
                                            <div className="d-inline-flex p-1">
                                               
                                                    <img src={projectUrl.hostUrl+"assets/img/icon/book.svg"} className="" alt="" style={{width:'18px'}} />
                                               
                                                    <input type="text" placeholder="Subject" name="subject" id="subject" required  onChange={handleChange} value={formFields.subject} className='ml-2'/>
                                               
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 pt-3">
                                        <div className="Input2">
                                            <div className="d-inline-flex p-1 w-100">
                                                
                                                    <img src={projectUrl.hostUrl+"assets/img/icon/penIcon.svg"} style={{width:'18px', height:'18px'}} alt="" />
                                               
                                                    <textarea name="message" id="" placeholder="Write your message here" className='ml-2' onChange={handleChange} value={formFields.message}  rows="4"></textarea>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <input type="hidden" name="token" id="token_name" />
                                        {/* <!--<div className="g-recaptcha" data-sitekey="6LdRa-8fAAAAAFjzBVi9_e8sZB13NX2aDiq4beDI"></div>--> */}
                                        {/* <!--<div className="g-recaptcha" data-sitekey="Your_reCAPTCHA_Site_Key"></div>--> */}
                                        <div className="g_recaptcha" id="g_recaptcha"></div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12 pt-3">
                                                <button type="submit" className="btn yellowBtn btnTyp1">
                                                    SUBMIT
                                                    <span className="first"></span>
                                                    <span className="second"></span>
                                                    <span className="third"></span>
                                                    <span className="fourth"></span>
                                                </button>
                                            </div>
                                            <div className="col-md-6 col-12 pt-3">
                                                <button type="reset" className="btn blackBtn text-white btnTyp2">
                                                    RESET
                                                    <span className="first"></span>
                                                    <span className="second"></span>
                                                    <span className="third"></span>
                                                    <span className="fourth"></span>
                                                </button>
                                            </div>
                                        </div>
                                        {/* <!-- <h6 className="pt-3 pl-2">Thank you, we'll be in touch soon!</h6> --> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactBody