const getBaseUrl = () => {
    // console.log(window.location);
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    // const { protocol, hostname, port } = window.location;
    let pr = port ? ':'+port : '';
    return protocol+"//"+ hostname+pr;
  }
  
  const apiUrls = 'https://api.collegeslike.com/api/';
  const projectUrl = {
    hostUrl:getBaseUrl()+"/",
    login: apiUrls+'user/login',
    homeSectionVideos : apiUrls+'home/section',
    fetchState : apiUrls+'user/helper/states',
    fetchCountries : apiUrls+'user/helper/countries',
    fetchStudentVideos : apiUrls+'videos/all',
    backpack : apiUrls+'user/backpack',
    forgetPassword: apiUrls+'user/forgot-password',
    userRegistration : apiUrls+'user/register',
    fetchCollegeDetails : apiUrls+'college/get-college-details',
    fetchCollegesLike : apiUrls+'college/search',
    addtoBackPack : apiUrls+'user/backpack/add',
    updateInterest : apiUrls+'user/backpack/update-interest',
    updateNotes : apiUrls+'user/backpack/update-notes',
    updateTier : apiUrls+'user/backpack/update-tier',
    deleteBackPack : apiUrls+'user/backpack/delete',
    sendBackpackEmail : apiUrls+'user/backpack/send-backpack-email',
    receiptBackpack : apiUrls+'user/backpack/get-backpack-from-email',
    seoGet : apiUrls+'seo/get',
    contact : apiUrls+'contact/send-message',
    getCollegeSuggestions: apiUrls+'college/suggestions',
    deleteAccount : apiUrls+'user/delete-account',
  };
// console.log(projectUrl);

export default projectUrl;