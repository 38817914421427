import React, { useEffect, useRef } from "react";
//import { useLocation } from "react-router-dom";

const GoogleTranslate = () => {
  const googleTranslateRef = useRef(null);
  //const location = useLocation();

  useEffect(() => {
    const initGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,es,zh-CN",
            //layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
          },
          googleTranslateRef.current
        );
      }
    };

    const loadGoogleTranslateScript = () => {
      const existingScript = document.querySelector('script[src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]');
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.async = true;
        document.body.appendChild(script);
      }
      window.googleTranslateElementInit = initGoogleTranslate;
    };

    loadGoogleTranslateScript();
    if (window.google && window.google.translate) {
      initGoogleTranslate();
    } else {
      window.googleTranslateElementInit = initGoogleTranslate;
    }

    // Cleanup is not needed because we are only initializing the translate element without altering DOM structure

  });

  return <div ref={googleTranslateRef} id="google_translate_element" style={{display:'none'}}></div>;
};

export default GoogleTranslate;
