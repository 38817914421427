import React from 'react'
import projectUrl from '../../ProjectComponents/Url'

function SchoolBody() {
  return (
    <>
        <section className="forSchool pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-5 pt-5 order-2 order-lg-1">
                            <img src={projectUrl.hostUrl+"assets/img/content-img/Content_schoolImg1.png"} width="100%" alt=""/>
                    </div>
                    <div className="col-12 col-lg-7 pt-5 order-1 order-lg-2">
                        <h1>For Schools</h1>
                        <p className="para1">If you've ever thought, “There ought to be a way to use the Internet to carry a lot of the work I do so that I can concentrate my energy on more targeted support of students,” CollegesLike carries that load for you. CollegesLike was created because College Counselors, their students, and family members need better and Free tools to support the great work that you already do.</p><br/> 

                          <h3>What CollegesLike Does</h3>  
                            
                          <p className="para1">CollegesLike is like Khan Academy for the college search and application process. Using super-fast video messages (for a TikTok generation), we take the basic information about college admission and present it in a fast, online format. Kids can watch our core instructional videos in 70 minutes and have a new vocabulary and understanding about colleges. They can also get information about specific colleges, create a list of colleges they are most interested in, and share it with you. </p><br/>
                            
                           <p className="para1"> When we created CollegesLike, we assumed our students and their families knew almost nothing about college admission. So our content starts at the very beginning, but more advanced users can drop-in at any point to pick up more information. The ability to surgically drop in at the right point and get up to speed should be tremendously helpful to you. And can we stress once more how fast CollegesLike is?</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-12 pt-5">
                        <div className="box-f p-4">
                            <div className="row">
                                <div className="col-2">
                                    <img src={projectUrl.hostUrl+"assets/img/icon/edu.svg"} width="100%" alt=""/>
                                </div>
                                <div className="col-10 p-3">
                                    <h4>How is CollegesLike Different?</h4>
                                </div>
                                <div className="col-12 pt-3">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Everything is 100% free. There are no subscriptions, no fees, and no ads.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>We approached the college application in a comprehensive way, but users can jump in where they want to.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>A cool video library of current college students and tour guides talking about their college.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>We help students find colleges that are culturally similar to each other.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Use use Google Maps to show students where colleges are located .</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>We didn't focus on the SAT and ACT in our college advising work. We believe tools based on test scores will be useless soon.                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Students can save their college research in a feature called, The Backpack.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Then they can share it with you, and you can give quick feedback for those targeted conversations students need.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 pt-5">
                        <div className="box-l p-4">
                            <div className="row">
                                <div className="col-2">
                                    <img src={projectUrl.hostUrl+"assets/img/icon/document.svg"} width="100%" alt=""/>
                                </div>
                                <div className="col-10 p-1">
                                    <h4>How to Use CollegesLike to Create a Streamlined College Counseling Program?</h4>
                                </div>

                                <div className="col-12 pt-3">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Offer a brief announcement to faculty and staff that you will be using CollegesLike to help students through the college admission process.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Host a ”Case Studies” workshop in 11th grade. To make it easier for you, CollegesLike has created this module, which you can download for free <a href="assets/pdf/Case%20Studies.pdf" download>here</a>.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Respond to questions from students about their college searches (which you already do, but now their questions will be more sophisticated).</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-1">
                                            <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"} width="80%" alt=""/>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <p>Have more time to make yourself available for higher-level conversations with students about colleges and the admission process.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </section>
    </>
  )
}

export default SchoolBody