import React from 'react'
import RegistrationBanner from './Components/RegistrationBanner'
import IndexFooter from '../Index/Components/IndexFooter'
// import TopBar from '../ProjectComponents/TopBar'

function RegistrationPage() {
  return (
    <>
        {/* topbar */}
        <div className='main bg-findColleges pb-4'>
          {/* <TopBar/> */}
          <RegistrationBanner/>
        </div>
        <IndexFooter/>
        
    </>
  )
}

export default RegistrationPage