import React from 'react'
import { Link } from 'react-router-dom';

function TermsConditionBody() {
    const tableContainerStyle = {
        overflowX: 'scroll',
        maxWidth: '100%',
    };

    const tdStyle = {
        minWidth: '300px',
    };
    return (
        <>
            <section className="termsCondition pt-4">
                <div className="container">
                    <h2>
                        Terms of Service
                    </h2>
                    <p>
                        These terms and conditions govern the use of and access to the CollegesLike website.
                    </p>
                    <h4>
                        1. Your Acceptance
                    </h4>
                    <p>Welcome to the Terms of Service for the www.CollegesLike.com website (“Site”). This is an agreement
                        between the Site, which is the owner and operator of www.CollegesLike.com, and you (“you” or “your” or
                        “user(s)”), a user of the Site.</p>
                    <p>Throughout this document, the words “CollegesLike,” “us,” “we,” and “our,” refer to our company and our
                        Site. Your ability to submit or transmit any information through our Site will be referred to as
                        “Content” throughout this Agreement.</p>
                    <p>By accessing the Site, you agree that you have read and understand this Agreement, and that your use of
                        the Site shall conform to this Agreement. We reserve the right to amend our Terms of Service or Privacy
                        and Cookies Policy without notice. The Privacy and Cookies Policy may be reviewed below, at the end of
                        the Privacy Policy.</p>
                    <p>This Agreement and the Privacy and Cookies Policy represent your entire agreement with CollegesLike with
                        respect to your access and use of the Site. If you do not agree to the Terms of Service or the Privacy
                        and Cookies Policy you must cease using our Site immediately.</p>
                    <h4>2. User Accounts</h4>
                    <p>In order to use our Site you may be required to register. You agree that all information you provide if
                        required to register is true, accurate, and complete. We reserve the right to refuse service or
                        subscription to any person or entity for any or no reason whatsoever. If required to register, you are
                        responsible for maintaining the confidentiality of the ID and password, and are fully responsible for
                        all activities that occur under your ID or password. You agree to immediately notify CollegesLike of any
                        unauthorized use of your ID or password or any other breach of security. You also agree to exit from
                        your account at the end of each session. CollegesLike cannot and will not be liable for any loss or
                        damage arising from your failure to do so. For more information on security , see our Privacy and
                        Cookies Policy.</p>
                    <p>It is up to you to take precautions to ensure that information, data, and software you download or
                        otherwise select for your use is free of viruses and other destructive items. When you use or register,
                        you may begin to receive information and other materials regarding products, services, and other offers
                        from time to time as described in our Privacy and Cookies Policy. Such solicitations may take the form
                        of e-mail messages or other forms. Solicitations will be made using the information provided to us in
                        any registration process.</p>
                    <h4>3. Disclaimer and Information Accuracy</h4>
                    <p>Our Site is to be used for general purposes. Although we strive to provide the most accurate information
                        possible, we cannot guarantee that any use of our Site will result in any desired results for you. We
                        cannot guarantee that all search results are accurate. In some instances we may use third party data,
                        although their data is generally accurate, errors may occur. You agree that any information or Content
                        found on our Site is “as-is” and with all faults.</p>
                    <h4>4. Posting of Content on CollegesLike</h4>
                    <p>CollegesLike may allow you or third parties to submit Content such as data, location information, text,
                        messages, tags or other materials for hosting and display through our Site. CollegesLike is only acting
                        as a repository of data. We make no guarantees as to the validity, accuracy or legal status of any user
                        Content. We do not endorse, condone or verify any Content submitted or hosted. We do not endorse,
                        condone or verify any products, statistics, or information posted on our Site. Content that is submitted
                        by users is also subject to our Privacy Policy. We are not required to host, display, or distribute any
                        of your Content and we may refuse to accept or transmit your Content, and may remove or delete them from
                        CollegesLike at any time.</p>
                    <p>Any Content submitted by you will be owned by you. However, by submitting any Content to our Site, you
                        hereby represent and warrant that you own all rights to your Content or, alternatively, that you have
                        the right to give us the license described in this Agreement. You also promise that you have paid and
                        will pay in full all fees or other payments related to the use of your Content. Finally, you promise
                        that your Content does not infringe on the intellectual property rights, privacy rights, publicity
                        rights, or other legal rights of any third party.</p>
                    <h4>5. Your License Grant to Us For Your Content</h4>
                    <p>When you submit any Content to us, you understand that this Content may be viewable by the public and
                        grant CollegesLike and its affiliates, users, representatives and assigns a non-exclusive, fully-paid,
                        royalty-free, irrevocable, world-wide, universal, transferable license to display, publicly perform,
                        distribute, store, broadcast, transmit, reproduce, modify, prepare derivative works and otherwise use
                        and reuse all or part of your post and anything we may make with your post through CollegesLike or any
                        other medium currently invented or invented in the future. We reserve the right to display
                        advertisements in connection with your Content, and to use your Content to advertise and promote
                        CollegesLike. Additionally, although you own all Content submitted by you, we own all layouts,
                        arrangement, metadata and images that are used to render your Content through our Site.
                        If you reside in the EU or other countries that recognize unalienable moral rights, you grant us the
                        broadest license that is legally allowed under the applicable law.</p>
                    <h4>6. Our License Grant to You</h4>
                    <p>We grant you a non-commercial personal, non-exclusive, revocable, limited license to use our Site . You
                        acknowledge that we maintain all right, title, and interest in our Site. This means you cannot sell any
                        Content from the Site, sell access to our Site, reverse engineer or otherwise attempt to copy or
                        duplicate our Site, or try and make money off of it without our express written permission. Even if we
                        offer access to our Site for free, you must still abide by these provisions, you must not copy or
                        otherwise use our Site in a manner prohibited by this section. You obtain no ownership rights when you
                        access our Site. This license may be terminated if you violate any provisions listed in this Agreement,
                        our Privacy Policy, or at our discretion.</p>
                    <h4>7. Code of Conduct</h4>
                    <p>When using our Site, you are responsible for your use of CollegesLike, and for any use of CollegesLike
                        made using your device. You also agree that your use of CollegesLike is for personal non-commercial use.
                        You agree not to access, copy, or otherwise use CollegesLike, including our intellectual property and
                        trademarks, except as authorized by these Terms of Service or as otherwise authorized in writing by
                        CollegesLike. You agree to the following limitations on your use of our Site:</p>
                    <ul className="pl-3">
                        <li>You will not copy, distribute or disclose any part of the Site in any medium, including without
                            limitation by any automated or non-automated “scraping”;</li>
                        <li>You will not use any automated system, including without limitation “robots,” “spiders,” “offline
                            readers,” etc., to access the Site in a manner that sends more request messages to CollegesLike
                            servers than a human can reasonably produce in the same period of time by using a conventional
                            on-line web browser (except that CollegesLike grants the operators of public search engines
                            revocable permission to use spiders to copy materials from the publicly available searchable indices
                            of the materials, not caches or archives of such materials);</li>
                        <li>You will not attempt to interfere with, compromise the system integrity or security, or decipher any
                            transmissions to or from the servers running the Site;</li>
                        <li>You will not take any action that imposes, or may impose at our sole discretion, an unreasonable or
                            disproportionately large load on our infrastructure;</li>
                        <li>You will not upload invalid data, viruses, worms or other software agents through the Site;</li>
                        <li>You will not collect or harvest any personally identifiable information, including account names,
                            from the Site;</li>
                        <li>You will not use the Site for any commercial solicitation purposes;</li>
                        <li>You will not access the Site through any technology or means other than those provided or authorized
                            by the Site;</li>
                        <li>You agree to act within the bounds of common decency when using our Site, this includes a
                            prohibition on posting any sexually explicit, graphic, or violent content;</li>
                        <li>You agree not to stalk, harass, bully or harm another individual;</li>
                        <li>You agree not to impersonate any person or entity or misrepresent your affiliation with a person or
                            entity;</li>
                        <li>You agree to use our Site only in a safe manner and in compliance with all laws;</li>
                        <li>You agree that you will not hold CollegesLike responsible for your use of our Site;</li>
                        <li>You agree not to violate any requirements, procedures, policies or regulations of networks connected
                            to CollegesLike;</li>
                        <li>You agree not to interfere with or disrupt the Site;</li>
                        <li>You agree not to hack, spam or phish us or other users;</li>
                        <li>You agree to provide truthful and accurate Content;</li>
                        <li>You agree to not violate any law or regulation and you are responsible for such violations;</li>
                        <li>You will not use our Site to post any false, misleading, unlawful, defamatory, obscene, invasive,
                            threatening, harassing , inflammatory, fraudulent Content;</li>
                        <li>You agree not to cause, or aid in, the destruction, manipulation, removal, disabling, or impairment
                            of any portion of our Site, including the de-indexing or de-caching of any portion of our Site from
                            a thirty party’s website, such as by requesting its removal from a search engine;</li>
                        <li>You will not upload any Content to our Site that includes any third-party intellectual property
                            unless you have permission from the owner to use it in the specific manner that you used it;</li>
                    </ul>
                    CollegesLike reserves the right to suspend or terminate any account at any time without notice or
                    explanation.

                    <h4> 8. Compliance with All Laws and Regulations</h4>
                    <p> CollegesLike is not responsible for your violation of any laws while using our Site. Users must comply
                        with all local, state, or federal laws regarding your use of our Site, including all driving laws. Our
                        Site is void where prohibited.</p>

                    <h4> 9. Intellectual Property Rights</h4>
                    <p> CollegesLike expended substantial time, effort, and funds to create the Site. You acknowledge and agree
                        that CollegesLike exclusively owns the copyright to (or have been granted licenses by third parties to
                        use) all rights, title, and interest and the information, data, databases, images, sound recordings,
                        audio and visual clips, and other content (collectively, "Content") provided by the Site.</p>

                    <p> Certain materials specifically designated as belonging to another party are not owned by CollegesLike.
                    </p>
                    <p> No copyrighted material or other Content may be performed, distributed, downloaded, uploaded, modified,
                        reused, reproduced, reposted, retransmitted, disseminated, sold, published, broadcast or circulated or
                        otherwise used in any way whatsoever except as expressly stated either in such materials or in this
                        Agreement without express written permission of CollegesLike or permission of the copyright owner. Any
                        modification of the Content, or any portion thereof, or use of the Content for any other purpose
                        constitutes an infringement of CollegesLike’s copyrights and other proprietary rights. Use of the
                        Content on any other website or other networked computer environment is prohibited without prior written
                        permission from CollegesLike.</p>

                    <p> The trademarks and logos used on the Site are owned by CollegesLike or are used with permission from the
                        respective owners. No use of any CollegesLike trademark or any other trademark listed is permitted
                        without express written consent.</p>

                    <h4> 10. Representations and Warranties</h4>
                    <p> THE CONTENT IS PROVIDED ON AN “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS. TO THE FULLEST EXTENT
                        PERMISSIBLE BY LAW, NEITHER COLLEGESLIKE. NOR ANY OF ITS OWNERS, EMPLOYEES, MANAGERS, OFFICERS OR AGENTS
                        MAKE ANY REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS
                        TO: (A) THE SITE; (B) ANY USER CONTENT; (C) OUR CONTENT; OR (D) SECURITY ASSOCIATED WITH THE
                        TRANSMISSION OF INFORMATION TO COLLEGESLIKE OR VIA THE SERVICE. IN ADDITION, WE DISCLAIM ALL WARRANTIES,
                        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND
                        FREEDOM FROM COMPUTER VIRUS.</p>
                    <p> COLLEGESLIKE DOES NOT REPRESENT OR WARRANT THAT THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED; THAT
                        DEFECTS WILL BE CORRECTED; OR THAT THE THE SERVER THAT MAKES THE SITE AVAILABLE IS FREE FROM ANY HARMFUL
                        COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES. COLLEGESLIKE DOES NOT MAKE ANY REPRESENTATIONS OR
                        WARRANTIES THAT THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE SITE IS ACCURATE, COMPLETE, OR
                        USEFUL. YOU ACKNOWLEDGE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. COLLEGESLIKE DOES NOT WARRANT
                        THAT YOUR USE OF THE SITE IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND COLLEGESLIKE SPECIFICALLY
                        DISCLAIMS ANY SUCH WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR
                        OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT SUCH JURISDICTION’S LAW IS
                        APPLICABLE TO YOU AND THIS AGREEMENT. BY ACCESSING OR USING THE SITE YOU REPRESENT AND WARRANT THAT YOUR
                        ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SITE. COLLEGESLIKE DOES NOT
                        ENDORSE ANY CONTENT AND SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY
                        FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR
                        OTHER CAUSE OF ANY KIND OR CHARACTER BASED UPON OR RESULTING FROM ANY CONTENT FOUND ON OR THROUGH
                        COLLEGESLIKE.</p>

                    <h4> 11. Limitation of Liability</h4>
                    <p> IN NO EVENT SHALL COLLEGESLIKE, ITS OWNERS, OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU
                        FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING
                        FROM (I) YOUR USE OR INABILITY TO USE THE SITE; (II) OUR SERVICES, ERRORS, MISTAKES, OR INACCURACIES OF
                        CONTENT, (III) ANY PERSONAL INJURY OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
                        OUR SITE, (IV) ANY INTERRUPTION, MISINFORMATION, INCOMPLETE INFORMATION, OR CESSATION OF TRANSMISSION TO
                        OR FROM OUR SITE TO YOU, (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO
                        OR THROUGH OUR SITE BY ANY THIRD PARTY, (VI) ANY FAILURE OF ANY THIRD PARTY INFORMATION LISTED ON OUR
                        SITE INCLUDING ANY FAILURES OR DISRUPTIONS WHETHER INTENTIONAL OR UNINTENTIONAL OR (VII) ANY ERRORS OR
                        OMISSIONS IN OUR SITE FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF OUR SITE,
                        WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
                        FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</p>

                    <p> For Jurisdictions that do not allow us to limit our liability: Notwithstanding any provision of these
                        Terms, if your jurisdiction has provisions specific to waiver or liability that conflict with the above
                        then our liability is limited to the smallest extent possible by law. Specifically, in those
                        jurisdictions not allowed, we do not disclaim liability for: (a) death or personal injury caused by its
                        negligence or that of any of its officers, employees or agents; or (b) fraudulent misrepresentation; or
                        (c) any liability which it is not lawful to exclude either now or in the future.</p>

                    <p> IF YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC STATEMENT REGARDING RELEASE THEN THE
                        FOLLOWING APPLIES. FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, WAIVE THE
                        APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, “A GENERAL RELEASE DOES NOT EXTEND TO
                        CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING
                        THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
                        DEBTOR. YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA CIVIL CODE. YOU HEREBY WAIVE ANY SIMILAR
                        PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED RELEASE.
                        YOUR ABILITY TO USE OUR SITE IS CONTINGENT ON YOUR AGREEMENT WITH THIS AND ALL OTHER SECTIONS OF THIS
                        AGREEMENT. YOU AGREE OUR TOTAL LIABILITY TO YOU IS NOT MORE THAN 10 US DOLLARS OR THE TOTAL AMOUNT YOU
                        SPENT WHILE USING OUR SITE WITHIN THE LAST SIX MONTHS, WHICHEVER IS GREATER.</p>

                    <h4> 12. Indemnity</h4>
                    <p> You agree to defend, indemnify and hold harmless CollegesLike, its owners, officers, directors,
                        employees and agents, from and against any and all claims, damages, obligations, losses, liabilities,
                        costs or debt, and expenses (including but not limited to attorney’s fees) arising from:</p>

                    <ul className="pl-3">
                        <li>your use of and access to the CollegesLike Site;</li>
                        <li>your violation of any term of these Terms of Service;</li>
                        <li>your violation of any third party right, including without limitation any copyright, property, or
                            privacy right; or</li>
                        <li>any claim that any of your Content caused damage to a third party</li>
                    </ul>

                    <p> This defense and indemnification obligation will survive this Agreement and your use of the Site. You
                        also agree that you have a duty to defend us against such claims and we may require you to pay for an
                        attorney(s) of our choice in such cases. You agree that this indemnity extends to requiring you to pay
                        for our reasonable attorneys’ fees, court costs, and disbursements. In the event of a claim such as one
                        described in this paragraph, we may elect to settle with the party/parties making the claim and you
                        shall be liable for the damages as though we had proceeded with a trial.</p>

                    <h4> 13. International Use and Export Control</h4>

                    <p> The CollegesLike makes no representations that the Site is appropriate for use in all locations, or that
                        transactions, products, instruments, or Content discussed are available or appropriate for sale or use
                        in all jurisdictions. As a United States-based corporation, CollegesLike is subject to U.S. export
                        controls and economic sanctions laws and regulations. You access the CollegesLike Site on your own
                        initiative, and are responsible for compliance with applicable local laws or regulations. Recognizing
                        the global nature of the Internet, you agree to comply with all local rules regarding online conduct and
                        acceptable Content. Specifically, you agree to comply with all applicable laws regarding the
                        transmission of data or information exported from the United States or the country in which you reside.
                        You further agree not to upload to the CollegesLike Site any data or software that cannot be exported
                        under U.S. government export laws without prior written government authorization, including, but not
                        limited to, certain encryption software. This assurance and commitment shall survive termination of this
                        Agreement. Please note that individuals in countries against which the United States, directly or
                        through the United Nations, has imposed economic sanctions (such as Iran and Cuba) or persons designated
                        by the U.S. government as Specially Designated Nationals and Blocked Persons may not be permitted to use
                        certain aspects of the Site or Content even though they may access the Site.</p>

                    <h4> 14. COPPA Compliance</h4>
                    <p> The safety and privacy of children is very important to us. We are committed to comply with the
                        requirements of all federal and state law, including the Children's Online Privacy Protection Act of
                        1998 ("COPPA"). For more information about COPPA, please visit the Federal Trade Commission's website at
                        COPPA Website. This Web Site is not intended for children under the age of 13. CollegesLike will not
                        knowingly register or otherwise collect Personal Information from persons who identify themselves as
                        being under the age of 13 during the registration process, without the prior consent of such child's
                        parent or guardian. By accepting our terms of service, you are affirming that you are 13 years of age or
                        older.</p>

                    <h4> 15. Linked Sites</h4>
                    <p> This Site may provide, or third parties may provide, links to other websites or resources. Because
                        CollegesLike has no control over such websites and resources, you acknowledge and agree that
                        CollegesLike is not responsible for the availability of such external websites or resources, and does
                        not endorse and is not responsible or liable for any content, advertising, products, or other materials
                        on or available from such websites or resources. Links to these third-party websites does not imply that
                        the websites are affiliated with or endorsed by CollegesLike or are legally authorized to use any
                        trademark, trade name, logo or copyright symbol of CollegesLike that is displayed in or accessible
                        through the links.</p>
                    <p> You further acknowledge and agree that CollegesLike shall not be responsible or liable, directly or
                        indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or
                        reliance on any such content, goods or services available on or through any such site or resource.
                        CollegesLike strongly encourages users to read all external and third-party website terms and conditions
                        and privacy policies.</p>

                    <h4> 16. Choice of Law</h4>
                    <p> This Agreement shall be governed by the laws in force in the state of California. The offer and
                        acceptance of this contract is deemed to have occurred in the state of California.</p>

                    <h4> 17. Forum of Dispute</h4>
                    <p> In the event of any controversy or claim arising out of or relating to this Agreement, or a breach
                        thereof, the parties hereto shall first attempt to settle the dispute by mediation, administered by the
                        American Arbitration Association under its Commercial Mediation Procedures. If settlement is not reached
                        within sixty days after service of a written demand for mediation, any unresolved controversy shall be
                        settled by arbitration administered by the American Arbitration Association. The number of arbitrators
                        shall be one. The place of arbitration shall be Los Angeles, CA. Judgment on the award rendered by the
                        arbitrator may be entered in any court having jurisdiction thereof. You agree that the unsuccessful
                        party in any dispute arising from or relating to this Agreement will be responsible for the
                        reimbursement of the successful party’s reasonable attorneys’ fees, court costs, and disbursements.</p>

                    <h4> 18. Severability</h4>
                    <p> In the event that a provision of this Agreement is found to be unlawful, conflicting with another
                        provision of the Agreement, or otherwise unenforceable, the Agreement will remain in force as though it
                        had been entered into without that unenforceable provision being included in it. If two or more
                        provisions of this Agreement are deemed to conflict with each other’s operation, CollegesLike shall have
                        the sole right to elect which provision remains in force.</p>

                    <h4> 19. Non-Waiver</h4>
                    <p> We reserve all rights permitted to us under this Agreement as well as under the provisions of any
                        applicable law. Our non-enforcement of any particular provision or provisions of this Agreement or the
                        any applicable law should not be construed as our waiver of the right to enforce that same provision
                        under the same or different circumstances at any time in the future.</p>

                    <h4> 20. Termination and Cancellation</h4>
                    <p> We may terminate or suspend service, without notice, though we will strive to provide a timely
                        explanation in most cases. If you wish to terminate this Agreement, you are solely responsible for
                        following proper termination procedures. Simply stop using our Site. Cancellation may result in the
                        immediate deletion of any Content that you have submitted to CollegesLike. Although your Content may not
                        be accessible we may keep such Content for a reasonably commercial time for legal and internal purposes.
                        All provisions of this Agreement which by their nature should survive termination shall survive
                        termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and
                        limitations of liability.</p>

                    <h4> 21. Assignment</h4>
                    <p> You may not assign your rights and/or obligations under this Agreement to any other party without our
                        prior written consent. We may assign our rights and/or obligations under this Agreement to any other
                        party at our discretion.</p>

                    <h4> 22. Electronic Communications</h4>
                    <p> You agree to be contacted by CollegesLike or on our behalf or by any other party to whom you may be
                        referred or whom you may contact via your utilization of our Site by mail, email, telephone calls, text
                        messages, and other platforms for marketing and non-marketing purposes associated with the business of
                        the Site. You also specifically agree that you have provided express consent to receive autodialed and
                        prerecorded non-marketing calls (e.g., calls to service your relationship with us) and text messages
                        from us or on our behalf at the telephone number(s) that you provide.</p>

                    <p> You confirm that all of your contact information that you provide if you Register is correct. You also
                        agree that you are the subscriber for any telephone number(s) provided and the authorized user for the
                        email address(es) provided. You also agree to notify us promptly if any of your number(s) or address(es)
                        change in the future. You understand that your wireless service provider’s message and data rates may
                        apply to the messages that we send to or receive from you.</p>

                    <h4> 23. California Users and Residents</h4>
                    <p> Pursuant to California Civil Code Section 1789.3, any questions about pricing, complaints, or inquiries
                        about CollegesLike must be addressed to our agent for notice and sent via email to: <Link
                            to={"mailto:hi@collegeslike.com"}>hi@collegeslike.com</Link></p>
                    <p> Lastly, California users are also entitled to the following specific consumer rights notice: The
                        Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer
                        Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at
                        (916) 445-1254 or (800) 952-5210.</p>
                    <p> Last Updated: September 27, 2021 © CollegesLike.com. All Rights Reserved.</p>
                    <p> Privacy and Cookies Policy</p>

                    <h4> 1. Introduction</h4>
                    <p> We are committed to safeguarding the privacy of our website visitors.</p>
                    <p> This policy applies where we are acting as a data controller with respect to the personal data of our
                        website visitors that is supplied through this website; in other words, where we determine the purposes
                        and means of the processing of that personal data.</p>
                    <p> We use cookies on our website. Insofar as required by applicable law, we will ask you to consent to our
                        use of cookies when you first visit our website. For more information about managing cookies, see
                        Section 14.</p>
                    <p> More information about us and our handling of your personal data is set out in our terms and conditions.
                    </p>

                    <h4> 2. How We Use Your Personal Data</h4>
                    <p>In the table below and elsewhere in this Section 2 we have set out:</p>
                    <ul className="pl-3">
                        <li> the general categories of personal data that we may process;</li>
                        <li> in the case of personal data that we did not obtain directly from you, the source and specific
                            categories of that data;</li>
                        <li> the purposes for which we may process personal data; and</li>
                        <li> the legal bases of the processing.</li>
                    </ul>
                </div>
                <div className="container">
                    <div className="table-container">
                        <style>
                            {`
          .table-container {
            ${tableContainerStyle}
          }
          td {
            ${tdStyle}
          }
        `}
                        </style>
                        <table className="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Category</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Source</th>
                                    <th scope="col">Purposes</th>
                                    <th scope="col">Legal basis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Usage data</th>
                                    <td style={{ width: '300px' }} >This is data about your use of our website and services, and may
                                        include your IP address, geographical location, browser type and version, operating
                                        system, referral source, length of visit, page views and website navigation paths,
                                        as well as information about the timing, frequency and pattern of your service use
                                    </td>
                                    <td>Our analytics tracking system</td>
                                    <td>Analysing the use of the website and services</td>
                                    <td>Legitimate interests, namely monitoring and improving our website and services</td>
                                </tr>
                                <tr>
                                    <th scope="row">Account data</th>
                                    <td>User account data with respect to our website</td>
                                    <td>You</td>
                                    <td>Operating our website, providing our services, ensuring the security of our website
                                        and services, maintaining back-ups of our databases and communicating with you</td>
                                    <td>Legitimate interests, namely the proper administration of our website and business
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Publication data</th>
                                    <td>Information that you post for publication on our website or through our services
                                    </td>
                                    <td>You</td>
                                    <td>Enabling publication and administering our website and services</td>
                                    <td>Legitimate interests, namely the proper administration of our website and business
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Enquiry data</th>
                                    <td>Information contained in any enquiry you submit to us regarding our products or
                                        otherwise</td>
                                    <td>You</td>
                                    <td>Offering, marketing and selling relevant products to you</td>
                                    <td>Consent</td>
                                </tr>
                                <tr>
                                    <th scope="row">Transaction data</th>
                                    <td>Information relating to transactions, including purchases of goods and services,
                                        that you enter into with us and/or through our website</td>
                                    <td>You</td>
                                    <td>Supplying the purchased products and keeping proper records of the transactions</td>
                                    <td>The performance of a contract between you and us and/or taking steps, at your
                                        request, to enter into such a contract and our legitimate interests, namely the
                                        proper administration of our website and business</td>
                                </tr>
                                <tr>
                                    <th scope="row">Notification data</th>
                                    <td>Information that you provide to us for the purpose of subscribing to our email
                                        notifications and/or newsletters</td>
                                    <td>You</td>
                                    <td>Sending you the relevant notifications and/or newsletters</td>
                                    <td>Consent</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div>

                        <p className="pt-4">We may process any of your personal data identified in this policy where necessary
                            for the establishment, exercise, or defense of legal claims, whether in court proceedings or in
                            an administrative or out-of-court procedure. The legal basis for this processing is our
                            legitimate interests, namely the protection and assertion of our legal rights, your legal rights
                            and the legal rights of others.</p>

                        <p>We may process any of your personal data identified in this policy where necessary for the
                            purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining
                            professional advice. The legal basis for this processing is our legitimate interests, namely the
                            proper protection of our business against risks.</p>
                        <p>In addition to the specific purposes for which we may process your personal data set out in this
                            Section 2, we may also process any of your personal data where such processing is necessary for
                            compliance with a legal obligation to which we are subject, or in order to protect your vital
                            interests or the vital interests of another natural person.</p>
                        <p>Please do not supply any other person's personal data to us, unless we prompt you to do so.</p>

                        <h4>3. Providing Your Personal Data to Others</h4>
                        <p>We may disclose your personal data to our insurers and/or professional advisers insofar as
                            reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing
                            risks, obtaining professional advice, or the establishment, exercise or defense of legal claims,
                            whether in court proceedings or in an administrative or out-of-court procedure.</p>

                        <p>We may disclose your personal data, directly or indirectly, to our cloud services, hosting
                            services, backup services and communications services providers, to enable them to provide those
                            services.</p>

                        <p>We may also disclose your personal data to the third parties identified elsewhere on our website
                            insofar as reasonably necessary for the purposes identified.</p>
                        <p>In addition to the specific disclosures of personal data set out in this Section 3, we may
                            disclose your personal data where such disclosure is necessary for compliance with a legal
                            obligation to which we are subject, or in order to protect your vital interests or the vital
                            interests of another natural person. We may also disclose your personal data where such
                            disclosure is necessary for the establishment, exercise or defense of legal claims, whether in
                            court proceedings or in an administrative or out-of-court procedure.</p>

                        <h4>4. International Transfers of Your Personal Data</h4>
                        <p>In this Section 4 and elsewhere on our website, we provide information about the circumstances in
                            which your personal data may be transferred from a location within the European Economic Area
                            (EEA) to a location outside the EEA.</p>

                        <p>In particular, we may make transfers to Australia, the Philippines, Singapore, Ukraine, the
                            United Arab Emirates and/or the United States of America for the purpose of enabling the
                            provision of hosting, back-up and content delivery services. These transfers will be protected
                            by appropriate safeguards, namely the use of the standard contractual clauses
                            (https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en)
                            and/or the Privacy Shield system (https://www.privacyshield.gov )</p>

                        <p>You acknowledge that personal data that you submit for publication through our website or
                            services may be available, via the internet, around the world. We cannot prevent the use (or
                            misuse) of such personal data by others.</p>

                        <h4>5. Retaining and Deleting Personal Data</h4>
                        <p>Personal data that we process for any purpose or purposes shall not be kept for longer than is
                            necessary for that purpose or those purposes. For specific questions about our data retention
                            and deletion policies, please email us at .</p>

                        <p>Notwithstanding the other provisions of this Section 5, we may retain your personal data where
                            such retention is necessary for compliance with a legal obligation to which we are subject, or
                            in order to protect your vital interests or the vital interests of another natural person.</p>

                        <h4>6. Security of Personal Data</h4>
                        <p>We will take appropriate technical and organizational precautions to secure your personal data
                            and to prevent the loss, misuse or alteration of your personal data.</p>
                        <p>Data relating to financial transactions that is sent from your web browser to our web server, or
                            from our web server to your web browser, will be protected using encryption technology.</p>

                        <p>You acknowledge that the transmission of unencrypted (or inadequately encrypted) data over the
                            internet is inherently insecure, and we cannot guarantee the security of data sent over the
                            internet.</p>

                        <p>You should ensure that any password you may use for our website is not susceptible to being
                            guessed, whether by a person or a computer program. You are responsible for keeping the password
                            you use for accessing our website confidential and we will not ask you for your password (except
                            when you log in to our website).</p>

                        <h4>7. Amendments</h4>
                        <p>We may update this policy from time to time by publishing a new version on our website. You
                            should check this page occasionally to ensure you are happy with any changes to this policy.</p>

                        <h4>8. Your Rights</h4>
                        <p>In this Section 8, we have summarized the rights that you have under data protection law. Some of
                            the rights are complex, and not all of the details have been included in our summaries.
                            Accordingly, you should read the relevant laws and guidance from the regulatory authorities for
                            a full explanation of these rights.</p>

                        <p>Your principal rights under data protection law are:</p>

                        <ul className="pl-3">
                            <li>the right to access;</li>
                            <li>the right to rectification;</li>
                            <li>the right to erasure;</li>
                            <li>the right to restrict processing;</li>
                            <li>the right to object to processing;</li>
                            <li>the right to data portability;</li>
                            <li>the right to complain to a supervisory authority; and</li>
                            <li>the right to withdraw consent.</li>
                        </ul>

                        <p>You have the right to confirmation as to whether or not we process your personal data and, where
                            we do, access to the personal data, together with certain additional information. That
                            additional information includes details of the purposes of the processing, the categories of
                            personal data concerned and the recipients of the personal data. Providing the rights and
                            freedoms of others are not affected, we will supply to you a copy of your personal data. The
                            first copy will be provided free of charge, but additional copies may be subject to a reasonable
                            fee.</p>

                        <p>You have the right to have any inaccurate personal data about you rectified and, taking into
                            account the purposes of the processing, to have any incomplete personal data about you
                            completed.</p>

                        <p>In some circumstances you have the right to the erasure of your personal data without undue
                            delay. Those circumstances include: the personal data are no longer necessary in relation to the
                            purposes for which they were collected or otherwise processed; you withdraw consent to
                            consent-based processing; you object to the processing under certain rules of applicable data
                            protection law; the processing is for direct marketing purposes; and the personal data have been
                            unlawfully processed. However, there are exclusions of the right to erasure. The general
                            exclusions include where processing is necessary: for exercising the right of freedom of
                            expression and information; for compliance with a legal obligation; or for the establishment,
                            exercise or defense of legal claims</p>

                        <p>In some circumstances you have the right to restrict the processing of your personal data. Those
                            circumstances are: you contest the accuracy of the personal data; processing is unlawful but you
                            oppose erasure; we no longer need the personal data for the purposes of our processing, but you
                            require personal data for the establishment, exercise or defense of legal claims; and you have
                            objected to processing, pending the verification of that objection. Where processing has been
                            restricted on this basis, we may continue to store your personal data. However, we will only
                            otherwise process it: with your consent; for the establishment, exercise or defense of legal
                            claims; for the protection of the rights of another natural or legal person; or for reasons of
                            important public interest.</p>

                        <p>You have the right to object to our processing of your personal data on grounds relating to your
                            particular situation, but only to the extent that the legal basis for the processing is that the
                            processing is necessary for: the performance of a task carried out in the public interest or in
                            the exercise of any official authority vested in us; or the purposes of the legitimate interests
                            pursued by us or by a third party. If you make such an objection, we will cease to process the
                            personal information unless we can demonstrate compelling legitimate grounds for the processing
                            which override your interests, rights and freedoms, or the processing is for the establishment,
                            exercise, or defense of legal claims.</p>
                        <p>You have the right to object to our processing of your personal data for direct marketing
                            purposes (including profiling for direct marketing purposes). If you make such an objection, we
                            will cease to process your personal data for this purpose.</p>

                        <p>You have the right to object to our processing of your personal data for scientific or historical
                            research purposes or statistical purposes on grounds relating to your particular situation,
                            unless the processing is necessary for the performance of a task carried out for reasons of
                            public interest.</p>

                        <p>To the extent that the legal basis for our processing of your personal data is:</p>
                        <ul className="pl-3">
                            <li>consent; or</li>
                            <li>that the processing is necessary for the performance of a contract to which you are party or
                                in order to take steps at your request prior to entering into a contract,</li>
                        </ul>

                        <h4>9. Third Party Websites</h4>
                        <p>Our website may includes hyperlinks to, and details of, third party websites.</p>
                        <p>We have no control over, and are not responsible for, the privacy policies and practices of third
                            parties.</p>

                        <h4>10. Updating Information</h4>
                        <p>Please let us know if the personal information that we hold about you needs to be corrected or
                            updated. Email us at hi@collegeslike.com.</p>

                        <h4>11. About Cookies and Local Storage</h4>
                        <p>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a
                            web server to a web browser and is stored by the browser. The identifier is then sent back to
                            the server each time the browser requests a page from the server.</p>
                        <p>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be
                            stored by a web browser and will remain valid until its set expiry date, unless deleted by the
                            user before the expiry date; a session cookie, on the other hand, will expire at the end of the
                            user session, when the web browser is closed.</p>
                        <p>Cookies do not typically contain any information that personally identifies a user, but personal
                            information that we store about you may be linked to the information stored in and obtained from
                            cookies.</p>

                        <p>Local storage is the storage of data sent by our web server to your web browser. This data is not
                            sent back to the web server.</p>

                        <h4>12. Cookies and Local Storage That We Use</h4>
                        <ul className="pl-3">
                            <li>authentication - we use cookies to identify you when you visit our website and as you
                                navigate our website;</li>
                            <li>ecommerce and shopping cart - we use cookies to enable the functioning of our ecommerce
                                systems and to maintain the state of your shopping cart;</li>
                            <li>analysis - we use cookies to help us to analyse the use and performance of our website and
                                services; and</li>
                            <li>cookie consent - we use cookies to store your preferences in relation to the use of cookies
                                more generally. We use local storage for the following purposes:</li>
                            <li>browser tab synchronisation - to synchronise state, available features and limits between
                                different web browser tabs;</li>
                            <li>save settings - to save your sidebar lock / unlock settings; and</li>
                            <li>ecommerce – to facilitate ecommerce functionality.</li>
                        </ul>

                        <h4>13. Cookies Used by Our Service Providers</h4>
                        <p>Our service providers use cookies and those cookies may be stored on your computer when you visit
                            our website.</p>
                        <p>We use Google Analytics to analyze the use of our website. Google Analytics gathers information
                            about website use by means of cookies. The information gathered relating to our website is used
                            to create reports about the use of our website. Google's privacy policy is available at:
                            https://www.google.com/policies/privacy/.</p>
                        <p>Cloudflare is used to identify malicious visitors to our website, to reduce the chance of
                            blocking legitimate users, and to provide customized services. For these purposes, Cloudflare
                            uses a cookie. Find out more about the Cloudflare cookies at
                            https://www.cloudflare.com/cookie-policy/.</p>

                        <h4>14. Managing Cookies</h4>
                        <p>Our website includes cookie management options. For these purposes, there are two different
                            categories of cookies. First, cookies that are necessary for the operation of our website and
                            the provision of our services. Second, cookies that we use for analytics purposes. To use our
                            website and services, your browser must accept the necessary cookies. However, you may accept or
                            reject the analytics cookies. You can manage the cookies that you accept from our website.</p>

                        <p>Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing
                            so vary from browser to browser, and from version to version. You can however obtain up-to-date
                            information about blocking and deleting cookies via these links:</p>

                        <ul className="pl-3">
                            <li><Link to={''}> https://support.google.com/chrome/answer/95647?hl=en (Chrome);</Link></li>
                            <li><Link to={''}>
                                https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences;
                                (Firefox);</Link></li>
                            <li><Link to={''}> http://www.opera.com/help/tutorials/security/cookies/ (Opera);</Link></li>
                            <li><Link to={''}>
                                https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
                                (Internet Explorer);</Link></li>
                            <li><Link to={''}> https://support.apple.com/kb/PH21411 (Safari); and</Link></li>
                            <li><Link to={''}> https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
                                (Edge).</Link></li>
                        </ul>

                        <p>Blocking all cookies will have a negative impact upon the usability of many websites.</p>
                        <p>If you block cookies, you will not be able to use all the features on our website.</p>
                        <p>Last Updated: September 27, 2021</p>
                        <p>© CollegesLike.com. All Rights Reserved.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsConditionBody