import React, { useState, useEffect } from 'react';
import projectUrl from '../../ProjectComponents/Url';
import { useNavigate } from 'react-router-dom';
import fetchData from '../../ProjectComponents/GlobalFetchApi';
function IndexSearch() {
    const [tellMeAboutValue, setTellMeAboutValue] = useState('');
    const [colleges, setColleges] = useState([]);
    const [findCollegesValue, setFindCollegesValue] = useState('');
    const [similarColleges, setSimilarColleges] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchColleges = async () => {
            if (tellMeAboutValue.length >= 3) {
                
                const data = { 'Name': tellMeAboutValue }; // Adjust according to your API's expected request body

                try {
                    const response = await fetchData.fetch(projectUrl.getCollegeSuggestions, {}, data);
                    setColleges(response.data); // Assuming the response structure
                } catch (error) {
                    console.error('Failed to fetch colleges:', error);
                }
            } else {
                setColleges([]); // Clear the list if input is less than 3 characters
            }
        };

        fetchColleges();
    }, [tellMeAboutValue]);

    useEffect(() => {
        // Reuse the fetchColleges logic for findCollegesValue
        const fetchSimilarColleges = async () => {
            if (findCollegesValue.length >= 3) {
                const data = { 'Name': findCollegesValue };

                try {
                    const response = await fetchData.fetch(projectUrl.getCollegeSuggestions, {}, data);
                    setSimilarColleges(response.data);
                } catch (error) {
                    console.error('Failed to fetch similar colleges:', error);
                }
            } else {
                setSimilarColleges([]);
            }
        };

        fetchSimilarColleges();
    }, [findCollegesValue]);

    const handleTellMeAboutChange = (e) => {
        const value = e.target.value;
        setTellMeAboutValue(value);
    };
    const handleItemClick = (slug) => {
        navigate(`/colleges/${slug}`); // Navigate to the college page with the slug
    };

    const handlefindCollegeClick = (slug) => {
        navigate(`/college/${slug}`); // Navigate to the college page with the slug
    };

    const handleFindCollegesChange = (e) => {
        const value = e.target.value;
        setFindCollegesValue(value);
        // You can perform any additional actions you need here
    };

    return (
        <>
            <section className="search_college pt-md-2 pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-2 pt-4 order-1 align-self-center">
                            <div className="row no-gutters">
                                <div className="col-lg-12 col-10">
                                    <p className="writing_title">To learn more about
                                        a specific college</p>
                                </div>
                                <div className="col-lg-12 col-2 align-self-start">
                                    <img src="assets/img/icon/Direct-right.svg" alt="" className="show-direction" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 pt-4 order-2">
                            <div className="tellMe_Ab search_cl_div">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={`${projectUrl.hostUrl}/assets/img/content-img/lf_mask.png`} width="" alt="" className="mask_image" />
                                    </div>
                                    <div className="col-12">
                                        <h4 className="text-white text-center pt-4">Tell Me About</h4>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="input_field mx-auto">
                                            <span>
                                                <img src="assets/img/icon/search-icon.svg" alt="" />
                                            </span>
                                            <input
                                                onChange={handleTellMeAboutChange}
                                                type="search"
                                                className="pl-2 college_name"
                                                data-type="tell-me-about"
                                                autoComplete="off"
                                                name="tellMeAbout"
                                                id="clg_name"
                                                placeholder="Enter a college name here"
                                                value={tellMeAboutValue}
                                            />

                                        </div>
                                    </div>
                                </div>

                                {colleges.length > 0 && (
                                    <div className="row no-gutters justify-content-center">
                                        <div className="col-11">
                                            <div className="listItem pl-3 pr-3">
                                                <ul className="dropdown_menu--animated dropdown_menu-7 suggestion_box">
                                                    {colleges.map((college) => (
                                                        <li key={college._id} onClick={() => handleItemClick(college.Slug)}>
                                                            {college.Name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="col-12 col-lg-4 pt-4 order-4 order-lg-3">
                            <div className="first_college search_cl_div">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={`${projectUrl.hostUrl}/assets/img/content-img/rt_mask.png`} width="" alt="" className="mask_image" />
                                    </div>
                                    <div className="col-12">
                                        <h4 className="text-white text-center pt-4">Find CollegesLike This</h4>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="input_field mx-auto">
                                            <span>
                                                <img src="assets/img/icon/search-icon.svg" alt="" />
                                            </span>
                                            <input
                                                onChange={handleFindCollegesChange}
                                                type="search"
                                                className="pl-2 college_name"
                                                autoComplete="off"
                                                name="findColleges"
                                                id="clg_name"
                                                placeholder="Enter a college name here"
                                                value={findCollegesValue}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {similarColleges.length > 0 && (
                                    <div className="row no-gutters justify-content-center">
                                        <div className="col-11">
                                            <div className="listItems pl-3 pr-3">
                                                <ul className="dropdown_menu--animated dropdown_menu-7 suggestion_box">
                                                    {similarColleges.map((college) => (
                                                        <li key={college._id} onClick={() => handlefindCollegeClick(college.Slug)}>
                                                            {college.Name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="col-12 col-lg-2 pt-4 order-3 order-lg-4 align-self-center">
                            <div className="row no-gutters">
                                <div className="col-lg-12 col-10">
                                    <p className="writing_title">Enter the name of a college and we'll find 10 that are like it.</p>
                                </div>
                                <div className="col-lg-12 col-2">
                                    <img src="assets/img/icon/Direct-left.svg" alt="" className="show_right_direction" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexSearch