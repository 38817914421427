import React, { useEffect, useState } from 'react'
import MethodBanner from './Components/MethodBanner'
import MethodologyBody from './Components/MethodologyBody'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';
// import TopBar from '../ProjectComponents/TopBar'
// import SEOHelmet from '../ProjectComponents/SEOHelmet'

function MethodologyPage() {
  // const page = 'methodology';
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('methodology');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);
  return (
    <>
         {/* topbar */}
         {/* <SEOHelmet page={page} /> */}
         <div className='bg-main main'>
          {/* <TopBar/> */}
          <MethodBanner/>
        </div>
        {/* body */}
        <MethodologyBody/>
        <IndexFooter/>
    </>
  )
}

export default MethodologyPage