import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import projectUrl from '../../ProjectComponents/Url';

function IndexCarousal() {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    prevArrow: <button type='button' className='slick-prev pull-left  btn-arrow'>Prev</button>,
    nextArrow: <button type='button' className='slick-next pull-right  btn-arrow'>Next</button>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 200,
        settings: 'unslick',
      },
    ],
  };

  return (
    <>
      <section className="schools_carousal bg-dark ">
        <div className="container">
          <Slider {...settings} className="slider">
            <div className="slide">
              <img src={`${projectUrl.hostUrl}/assets/img/brands/harvard.png`} alt="" className="m-auto d-block" />
            </div>
            <div className="slide">
              <img src={`${projectUrl.hostUrl}/assets/img/brands/colgate.png`} alt="" className="m-auto d-block" />
            </div>
            <div className="slide">
              <img src={`${projectUrl.hostUrl}/assets/img/brands/brown.png`} alt="" className="m-auto d-block" />
            </div>
            <div className="slide">
              <img src={`${projectUrl.hostUrl}/assets/img/brands/south-carilona.png`} alt="" className="m-auto d-block" />
            </div>
            <div className="slide">
              <img src={`${projectUrl.hostUrl}/assets/img/brands/hawai-pacific.png`} alt="" className="m-auto d-block" />
            </div>
            <div className="slide">
              <img src={`${projectUrl.hostUrl}/assets/img/brands/mesa-community-college.png`} alt="" className="m-auto d-block" />
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}

export default IndexCarousal;
