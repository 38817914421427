import React from 'react'
import projectUrl from '../../ProjectComponents/Url'
import { Link } from 'react-router-dom'

function MethodologyBody() {
    return (
        <>
            <section className="method pt-4 pb-4">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-3 p-3">
                            <h2>Methodology</h2>
                            <p>The magic of CollegesLike begins with a lot of data. The data on 6000+ colleges and universities comes from the U.S. Department of Education’s Integrated Postsecondary Education Data System (IPEDS). Then we eliminated colleges that did not meet certain criteria. Colleges in our search must be: </p>
                        </div>
                        <div className="col-12 col-lg-3 pt-3">
                            <div className="row pb-2">
                                <div className="col-lg-10 col-11 ">
                                    <div className="bxNo1">
                                        <p>Primarily academic institutions. Not trade schools, like beauty colleges.</p>
                                    </div>
                                    <div className="bxNo2">
                                        <p>Awards undergraduate college degrees. Graduate-only institutions were removed.</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-1 blankBx">
                                    <div className="">

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="bxNo3 p-3">
                                        <p>Accredited by a regional accrediting organization.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 pt-3">
                            <div className="row justify-content-end">
                                <div className="col-lg-11 col-12">
                                    <div className="bxNo4">
                                        <p>Located within the 50 United States or the District of Columbia. We know that Puerto Rico, Guam, and other territories are part of America, but their colleges are so different that they don’t compare well to mainland colleges.</p>
                                    </div>
                                </div>
                                <div className="col-12 pl-lg-4 pl-2  pt-2">
                                    <div className="bxNo5">
                                        <p>Currently open for business. Not those that are closing or closed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 pl-2 pt-3">
                            <div className="row no-gutters">
                                <div className="col-lg-12 col-11 order-2 order-lg-1">
                                    <div className="bxNo6">
                                        <p>Participates in the U.S. Department of Education’s Integrated Postsecondary Education Data System (IPEDS). If we don’t have data, we can’t make a comparison.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-1 pt-2 pr-2 order-1 order-lg-2">
                                    <div className="bxNo7 ">

                                    </div>
                                </div>
                                <div className="col-12 order-3 pt-2">
                                    <div className="bxNo8">
                                        <p>Predominantly brick-and-mortar institutions. Not online colleges.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- this is third section of methodology --> */}
            <section className="details_methodology">
                <div className="container">
                    <hr/>
                        <div className="row">
                            <div className="col-12 col-lg-7">
                                <h6>The colleges that remained are the 3000+ institutions that are included in CollegesLike. This gave us a core snapshot of colleges, and especially student demographics, academics, admission standards, and financial data.</h6>
                                <p>But that’s just data and doesn’t say enough about colleges. So we added some additional objective data, including: NCAA division, Historically Black Colleges and Universities, Tribal colleges, women’s colleges, military service academies, religious affiliations, non-profit or for-profit status, region/location, and the special educational focuses of colleges.</p>
                                <p>Next, we brought the secret sauce! The staff have a combined 112 years of service working on college campuses, and while everyone has worked professionally in Admission, three members of the team were Vice Presidents of Admission at colleges and universities that are consistently ranked among the Top 10 in America. We argued about things like, “What are the ‘Southern Ivies’?” and, “How much are Brandeis and Pomona like the NESCAC colleges?” Answers to these questions are subjective, but we came to a consensus and figured out which campus cultures are similar and which ones are dissimilar.</p>
                                <p>All of this – national data, objective data, and campus culture - resulted in 250+ different metrics. Finally, we had to figure out how much to weigh each of the 250+ metrics so that the results make sense.</p>
                                <p>The Engineering Team took the data from the Data & Search Engine Team and wrote it into an algorithm, using MYSQL query language. Then the Engineering Team put the search engine into the website using PHP.</p>
                            </div>
                            <div className="col-12 col-lg-5">
                                <img src={projectUrl.hostUrl+"assets/img/content-img/method_cnt.png"} width="100%" alt=""/>
                            </div>
                            <div className="col-12 pt-4">
                                <h6>Why are some colleges not included in CollegesLike?</h6>
                                <p>There are 3000+ colleges and universities included in CollegesLike! Yet some colleges and universities are not included. In order to be included in CollegesLike, a college must meet all of the requirements in our list.</p>
                                <p>At some point, we may choose to expand our search parameters. There are some fine colleges outside of the 3000+ that were included on this website. But at this time, these terms define which colleges are included in CollegesLike.</p>
                                <h6>Might the search results change if I perform the same search at a later date?</h6>
                                <p>Absolutely, for several reasons:</p>
                            </div>
                            <div className="col-12 pt-2">
                                <div className="row no-gutters">
                                    <div className="col-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt=""/>
                                    </div>
                                    <div className="col-11 pt-1 pl-2">
                                        <h6>Colleges evolve and change. Their institutional data is evolving, not static.</h6>
                                    </div>

                                    <div className="col-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt=""/>
                                    </div>
                                    <div className="col-11 pt-1 pl-2">
                                        <h6>CollegesLike will continue to add more data sources over time.</h6>
                                    </div>

                                    <div className="col-1">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/yellowtick.svg"} className="float-right" alt=""/>
                                    </div>
                                    <div className="col-11 pt-1 pl-2">
                                        <h6>CollegesLike will be using Artificial Intelligence and algorithms to make the responses more precise.</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-5">
                                <h6>What if I disagree with CollegesLike’s data?</h6>
                                <p>Smart people can disagree with each other. You can send us an email and carefully explain what your concern is. We respond to polite and reasonable emails. </p>
                                <p>Send it to <Link to={projectUrl.hostUrl+"contact"}>hi@collegeslike.com</Link>.</p>
                            </div>
                        </div>
                </div>
            </section>
        </>
    )
}

export default MethodologyBody