import React from 'react'
import { Link } from 'react-router-dom'
import projectUrl from '../../ProjectComponents/Url'

function SchoolAuth() {
  return (
    <>
        <section className="TryAuth pt-5 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 trySign align-self-center">
                        <h1 className="text-center pb-3">Try it Right Now!</h1>
                        <div className="school_btm_Lg">
                            <div className="d-flex justify-content-center">
                                <Link to={projectUrl.hostUrl+"login"}>
                                    <button className="btn bg-transparent">SIGN UP</button>
                                </Link>
                                <Link to={projectUrl.hostUrl+"login"}>
                                    <button className="btn bg-transparent ">LOG IN</button>
                                </Link>
                            </div>
                        </div>
                </div>
                <div className="col-12 col-md-6">
                    <img src={projectUrl.hostUrl+"assets/img/content-img/school_btm_lg.png"} width="100%" className="school_btm_Lg" alt=""/>
                    <img src={projectUrl.hostUrl+"assets/img/content-img/school_btm_sm.png"} width="100%" className="school_btm_sm" alt=""/>

                    <div className="school_btm_sm trySign">
                        <div className="d-flex justify-content-center">
                            <Link to={projectUrl.hostUrl+"login"}>
                                <button className="btn bg-transparent">SIGN UP</button>
                            </Link>
                            <Link to={projectUrl.hostUrl+"login"}>
                                <button className="btn bg-transparent ">LOG IN</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default SchoolAuth