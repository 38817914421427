import React, { useEffect, useState } from 'react'
import CollegesLikeThisBanner from './Components/CollegesLikeThisBanner'
import CollegesLikeThisBody from './Components/CollegesLikeThisBody'
import IndexFooter from '../Index/Components/IndexFooter'
// import TopBar from '../ProjectComponents/TopBar'
import { useParams } from 'react-router-dom'
// import SEOHelmet from '../ProjectComponents/SEOHelmet'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData'
import { updateMetaTags } from '../ProjectComponents/updateMetaTags'

function CollegesLikeThisPage() {
  const {slug} = useParams();
  console.log(slug);
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('college',slug);
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, [slug]);
  //console.log(seoData);

 
  // const page = 'college';

  return (
    <>  
         {/* topbar */}
         <div className='bg-findColleges main'>
            {/* <TopBar/> */}
            <CollegesLikeThisBanner/>
        </div>
        <CollegesLikeThisBody/>
        
        {/* footer */}
        <IndexFooter/>
    </>
  )
}

export default CollegesLikeThisPage