// src/utils/fetchSeoData.js
import axios from 'axios';

const API_KEY = "8c655bfc-a035-4b4c-af1a-39cf1a10bcdb";
const API_ENDPOINT = "https://api.collegeslike.com/api/seo/get";

export const fetchSeoData = async (page, college_id) => {
  try {
    const response = await axios.post(
      API_ENDPOINT,
      { page, college_id },
      { headers: { "x-api-key": API_KEY } }
    );
    const result = {title:response.data.data.title,descriptions:response.data.data.description,keywords:response.data.data.keywords};
    return result;
  } catch (error) {
    console.error("Error fetching SEO data:", error);
    return { title: "CollegesLike", descriptions: "CollegesLike" };
  }
};
