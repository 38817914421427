import React from 'react'
// import TopBar from '../ProjectComponents/TopBar'
import DeleteBanner from './Components/DeleteBanner'
import IndexFooter from '../Index/Components/IndexFooter'
import DeleteBody from './Components/DeleteBody'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
const token = localStorage.getItem("token");

function DeleteAccountPage() {
  const navigate = useNavigate();
  useEffect(()=>{
    if (!token) {
      // If no token is found, navigate to the login page
      navigate("/login");
      return;
    }
  },[navigate])
  return (
    <>
        {/* topbar */}
        <div className='bg-main main'>
          {/* <TopBar/> */}
          {/* <TopBar/> */}
          <DeleteBanner/>
        </div>
            <DeleteBody/>
        <IndexFooter/>
    </>
  )
}

export default DeleteAccountPage