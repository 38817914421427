import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import TopBar from '../ProjectComponents/TopBar'
import LoginBody from './Components/LoginBody'
import IndexFooter from '../Index/Components/IndexFooter'
//import SEOHelmet from '../ProjectComponents/SEOHelmet';
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';

function LoginPage() {
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('login');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);

  const navigate = useNavigate();
  const page = 'login';
  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn') === 'true'; // Ensure you're checking the correct value as stored in localStorage
    if (loggedIn) {
      navigate('/backpack'); // Adjust the path as necessary for your homepage
    }
  }, [navigate]); // Dependency array ensures this effect only runs once on component mount
  return (
    <>
        {/* topbar */}
        <div className='loginSignup main'>
          {/* <TopBar/> */}
          <LoginBody/>
        </div>

        {/* footer */}
        <IndexFooter/>

    </>
  )
}

export default LoginPage