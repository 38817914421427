import React, { useEffect, useState } from 'react'
import TellMeAboutBanner from './Components/TellMeAboutBanner'
import TellMeAboutBody from './Components/TellMeAboutBody'
import IndexFooter from '../Index/Components/IndexFooter'
import { fetchSeoData } from '../ProjectComponents/fetchSeoData';
import { updateMetaTags } from '../ProjectComponents/updateMetaTags';

function TellMeAboutPage() {
  const [seoData, setSeoData] = useState({ title: "", descriptions: "", keywords: "" });

  useEffect(() => {
    const getSeoData = async () => {
      const data = await fetchSeoData('tell-me-about');
      setSeoData(data);
      updateMetaTags(data.title, data.descriptions, data.keywords);
    };

    getSeoData();
  }, []);
  
  // const page ='tell-me-about';
  return (
    <>
        
        <div className='bg-main main'>
          {/* <TopBar/> */}
            <TellMeAboutBanner/>
        </div>
            <TellMeAboutBody/>
        <IndexFooter/>
    </>
  )
}

export default TellMeAboutPage