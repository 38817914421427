import React, { useEffect, useState } from 'react';
import $ from 'jquery';
// Ensure Bootstrap's JavaScript is imported after jQuery
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link } from 'react-router-dom';
import projectUrl from '../../ProjectComponents/Url';
import MapWithMarker from '../../ProjectComponents/MapWithMarker';
import { useParams, useNavigate } from 'react-router-dom';
import fetchData from '../../ProjectComponents/GlobalFetchApi';
import CustomModal from '../../Index/Components/VdPlayer';

function CollegeDetailsBody() {
    const { slug } = useParams(); 
    const [collegeDetails, setCollegeDetails] = useState({});
    const [selectedVideoId, setselectedVideoId] = useState();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchCollegeDetails = async () => {
            console.log(slug);
            const requestBody = {
                college : slug
            }

            try {
                const response = await fetchData.post(projectUrl.fetchCollegeDetails,{},requestBody);
                setCollegeDetails(response.data);
                // console.log(collegeDetails);
            } catch (error) {
                console.error("Failed to fetch college details:", error);
            }
        };

        fetchCollegeDetails();

    },[slug])

  
    useEffect(() => {
        // Initialize tooltips with the option to allow HTML content
        $('[data-toggle="tooltip"]').tooltip({
            html: true // Enable HTML content in tooltips
        });

        // Cleanup function to dispose of tooltips when the component unmounts
        return () => {
            $('[data-toggle="tooltip"]').tooltip('dispose');
        };
    }, []);

    const handelAddBackpack= (collegeId)=>{
        const token = localStorage.getItem("token"); // Retrieve the token from localStorage
        $.confirm({
            title: 'Backpack add',
            content: 'Do you want to add this college in your list?',
            
            buttons: {
                Add :{
                  btnClass: 'btn-red',
                  action :async function () {
                    const requestBody = {
                        college_id : collegeId
                    }
                    const config = {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      };
                    try {
                        
                        const response = await fetchData.post(projectUrl.addtoBackPack, config,requestBody);
                        $.alert({
                            title:'Success !',
                            content:`${response.message}`,
                            button:{
                                ok:{
                                    btnClass: 'btn-green'
                                }
                            }
                        });
                        
                    } catch (error) {
                        if(error.response && (error.response.status === 401) ){
                            $.alert({
                              title: 'Login Please!',
                              content: error.response.data.message, // Assuming the message is a string
                              btnClass: 'btn-green',
                              buttons: {
                                  ok: function() {
                                    localStorage.clear();
                                    navigate('/login');
                                    console.log('hello');
                                  }
                              }
                          });
                        }
                    }
                  },
                },
                cancel: function () {
                   
                },
                
            }
          });
        
    }

    return (
        <>
            {/* <Link type="button" classNameName="btn btn-primary" data-toggle="tooltip" title="<em>Dashboard</em>"><i classNameName="las la-tachometer-alt"></i></Link> */}
            <div className="detailsOfCollege">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-7 ">

                                {/* <!-- <img src="" alt=""> --> */}
                                <div className="row pt-3">
                                    <div className="col-lg-auto col-12">
                                        <h2>{collegeDetails.Name}</h2>
                                    </div>
                                    <div className="col-lg-1 col-12 align-self-center">
                                        {collegeDetails.hasVideo?<Link onClick={()=>{setselectedVideoId(collegeDetails.Youtube); setShowModal(true)}}>
                                            <img src={projectUrl.hostUrl+"/assets/img/icon/play_yellow.svg"} alt="" />
                                       </Link>:<div></div>}
                                    </div>
                                </div>
                                {collegeDetails.hasWebsite?<Link to={collegeDetails.Website} target="_blank" className="">
                                <div className="row no-gutters">
                                    <div className="col-auto">
                                        <img src={projectUrl.hostUrl+"assets/img/icon/globe.svg"}
                                            className="filter_cont" alt="" />
                                    </div>
                                    <div className="col-auto pl-2 pt-1 ">
                                        <h6>{collegeDetails.Website}</h6>
                                    </div>
                                </div>
                            </Link>:<div></div>}
                                
                                <div className="collegeData pt-3">
                                    <p><span className="h6">{collegeDetails.State}</span></p>
                                    <p className="pb-4">{collegeDetails.Carnegie}</p>
                                    {collegeDetails.hasParagraph?<p><span className='bold-1'>Our Independent Review:</span> <span>{collegeDetails.Paragraph}</span></p>:<p></p>}
                                    
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="row no-gutters pt-3">

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.Control}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.Enrollment}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.CarnegieFilter}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.Setting}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.Rolling}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.Disabilities}
                                                </h6> 
                                            </div> 
                                            <div className="col-1">
                                                        <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                            className="float-right" alt=""
                                                            />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>In-State Tuition (2021) <Link
                                                        className="text-dark" data-toggle="tooltip" data-html="true" title="<ul><li>In-State Tuition is for residents of that state, but
most students don’t pay the full “sticker” price
of tuition. To estimate YOUR cost, use the
college’s Net Price Calculator.</li></ul>"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                                    </Link>: <span>{collegeDetails.InStateTution} </span></h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>Out-of-State Tuition (2021) <Link
                                                        className="text-dark" data-toggle="tooltip" data-html="true" title="<ul><li>Most students don’t pay the full “sticker” price
of tuition. To estimate YOUR cost, use the
college’s Net Price Calculator.</li></ul>"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                                    </Link>: <span> {collegeDetails.OutOfStateTution}</span></h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.CommonApp}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.GrantsCreditForAP}</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <div className="row no-gutters pt-3">

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.FacultyRatio}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.Women}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.AdmitRate}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.NCAA}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.GraduationRate}</h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>Commitment to Financial Aid <Link
                                                        className="text-dark" data-toggle="tooltip" data-html="true"
                                                        title="<ul><li>Exceptional: >50%   </li><li>Great: 25-49%</li><li>Good: 11-24%</li><li>Standard Aid: < 10%</li></ul>"><i
                                                            className="fa fa-info-circle" aria-hidden="true"></i>
                                                    </Link>: <span>{collegeDetails.Financial}</span></h6>
                                            </div>
                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>Net Price Calculator: <span><Link style={{overflowWrap: 'break-word'}}
                                                            to={collegeDetails.NetPriceCalculator}
                                                            target="_blank">{collegeDetails.NetPriceCalculator}</Link></span>
                                                </h6>
                                            </div>

                                            <div className="col-1">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/tick.svg"}
                                                    className="float-right" alt="" />
                                            </div>
                                            <div className="col-11 pt-1 pl-2">
                                                <h6>{collegeDetails.TOEFL}</h6>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                                <div className="collegeData pt-3">
                                    <p><span className="h6">Special Features:</span> {collegeDetails.SpecialFeatures}</p>
                                    
                                    {collegeDetails.hasMissonUrl?
                                        <p> 
                                                <span className="h6">Mission Statement: </span>
                                                <Link to={collegeDetails.MissionStatement}>
                                                    {collegeDetails.MissionStatement}
                                                </Link>     
                                        </p>
                                        :
                                        <p>
                                        <span className="h6">Mission Statement: </span>
                                        <span>
                                            {collegeDetails.MissionStatement}
                                        </span> 
                                        </p>}
                                </div>
                                
                            </div>

                            <div className="col-12 col-md-12 col-lg-5 pt-5  ">
                              
                                <div className='border-2'>
                                    <MapWithMarker lat={collegeDetails.Lat} lng={collegeDetails.Lon} tooltip={collegeDetails.Name +' , '+ collegeDetails.State } />
                                </div>
                                
                        {collegeDetails.hasCulture?
                        <div class="cultural-indicators mt-5 mb-5 ">
                            <div class="card p-2">
                                <div class="card-header" style={{background: '#def96a', color: '#000'}}>
                                    <h6 class="p-0 m-0">Our Campus Culture Indicators</h6>
                                </div>
                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Political:</h6>
                                </div>
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>Liberal</h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Conservative</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress"  style={collegeDetails.Culture && collegeDetails.Culture.Political && collegeDetails.Culture.Political.Width?{width: `${collegeDetails.Culture.Political.Width}%`, marginLeft:`${collegeDetails.Culture.Political.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>

                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Parties and Alcohol:</h6>
                                </div>
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>More</h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Less</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Parties && collegeDetails.Culture.Parties.Width?{width: `${collegeDetails.Culture.Parties.Width}%`, marginLeft:`${collegeDetails.Culture.Parties.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>


                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Athletics:</h6>
                                </div>
                                
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>Big</h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Small</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Athletics && collegeDetails.Culture.Athletics.Width?{width: `${collegeDetails.Culture.Athletics.Width}%`, marginLeft:`${collegeDetails.Culture.Athletics.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>


                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Greek Life:</h6>
                                </div>
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>Big</h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Small</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Greek_Life && collegeDetails.Culture.Greek_Life.Width?{width: `${collegeDetails.Culture.Greek_Life.Width}%`, marginLeft:`${collegeDetails.Culture.Greek_Life.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>


                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Housing:</h6>
                                </div>
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>Commuter</h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Residential</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Housing && collegeDetails.Culture.Housing.Width?{width: `${collegeDetails.Culture.Housing.Width}%`, marginLeft:`${collegeDetails.Culture.Housing.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>


                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Activism:</h6>
                                </div>
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>Social Justicey</h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Traditional</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Activism && collegeDetails.Culture.Activism.Width?{width: `${collegeDetails.Culture.Activism.Width}%`, marginLeft:`${collegeDetails.Culture.Activism.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>


                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Funky vs Conforming:</h6>
                                </div>
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'}  class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>Independent </h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Traditional</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Funky && collegeDetails.Culture.Funky.Width?{width: `${collegeDetails.Culture.Funky.Width}%`, marginLeft:`${collegeDetails.Culture.Funky.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>

                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Career-Oriented:</h6>
                                </div>
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>More </h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Less</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Career_Oriented && collegeDetails.Culture.Career_Oriented.Width?{width: `${collegeDetails.Culture.Career_Oriented.Width}%`, marginLeft:`${collegeDetails.Culture.Career_Oriented.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>


                                <div class="card-body subheader mt-2 p-2">
                                    <h6 class="p-0 m-0">Scholarly:</h6>
                                </div>
                                
                                <ul class="list-group list-group-flush border border-primary">
                                    <Link to={'/'} class="list-group-item list-group-item-action">
                                        <div class="row pl-2 pr-2">
                                            <div class="col-6 p-0">
                                                <h6>Academic </h6>
                                            </div>
                                            <div class="col-6 p-0">
                                                <h6 class="float-right">Intellectual</h6>
                                            </div>

                                            <div class="col-12 p-0 pl-3 pr-3">
                                                <div class="custom_progress-bar">
                                                    <div class="custom_progress" style={collegeDetails.Culture && collegeDetails.Culture.Scholarly && collegeDetails.Culture.Scholarly.Width?{width: `${collegeDetails.Culture.Scholarly.Width}%`, marginLeft:`${collegeDetails.Culture.Scholarly.MarginLeft}%`}:{}}></div>
                                                    <div class="circle"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </ul>



                            </div>

                        </div>:<div></div>}
                    </div>


                            <div className="col-12 col-md-12 col-lg-7 pt-5 ">
                            <div className={`row collegeDetailsBtn m-1 ${collegeDetails.hasCulture ? "collegeDetailsBtn_Button_Position" : ""} mt-5`}>
                                    <div className="col-lg-6 col-12">
                                    
                                            <button className="btnTyp2" onClick={()=>{handelAddBackpack(collegeDetails._id)}}
                                            >
                                                <img src={projectUrl.hostUrl+"assets/img/icon/plusIcon.svg"} alt="" className='mr-2' 
                                                    />Backpack
                                                        <span className="first"></span>
                                                        <span className="second"></span>
                                                        <span className="third"></span>
                                                        <span className="fourth"></span>
                                                
                                            </button>
                                    
                                        <div className="row no-gutters">
                                            <div className="col-10 order-2 order-lg-1 align-self-end">
                                                <p className="writing_title">Add this college to your follow up list.</p>
                                            </div>
                                            <div className="col-2 order-1 order-lg-2">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/Direct-right.svg"}
                                                    width="100%" alt="" className="show_right_direction"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">

                                        <Link to={`/college/${collegeDetails.Slug}`}>
                                            <button className="btnTyp1">
                                                Search colleges like this
                                                <span className="first"></span>
                                                <span className="second"></span>
                                                <span className="third"></span>
                                                <span className="fourth"></span>
                                            </button>
                                        </Link>
                                        <div className="row no-gutters">

                                            <div className="col-2  pl-2">
                                                <img src={projectUrl.hostUrl+"assets/img/icon/Direct-left.svg"}
                                                    width="100%" alt="" className="show-direction"
                                                    />
                                            </div>
                                            <div className="col-10 pl-3 align-self-end">
                                                <p className="writing_title">To find similar colleges to this one</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>

                        </div>
                    </div>
                </div>
                <CustomModal
                show={showModal}
                onHide={() => setShowModal(false)}
                videoId={selectedVideoId}
            />
        </>
    );
}

export default CollegeDetailsBody;
